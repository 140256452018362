import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ApiRepos } from '../../../contracts/ContractVars';
import './Settings.css';

const Settings = () => {
    // State for vehicle types
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [showVehicleDialog, setShowVehicleDialog] = useState(false);
    const [currentType, setCurrentType] = useState(null);
    const [vehicleFormData, setVehicleFormData] = useState({
        name: '',
        description: '',
        carbonEmission: 0
    });

    // State for content management
    const [contents, setContents] = useState([]);
    const [showContentDialog, setShowContentDialog] = useState(false);
    const [currentContent, setCurrentContent] = useState(null);
    const [contentFormData, setContentFormData] = useState({
        locationId: '',
        content: ''
    });

    // Shared state
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    // Location options for content
    const locationOptions = [
        { id: 'quote-form-bottom', label: 'Bottom of Quote Form' },
        { id: 'order-form-bottom', label: 'Bottom of Order Form' },
        { id: 'sidebar-menu-bottom', label: 'Bottom of Sidebar Menu' },
        { id: 'logged-in-home', label: 'Logged In Home Page' },
        { id: 'all-pages-header', label: 'Header of All Pages' }
    ];

    useEffect(() => {
        Promise.all([
            fetchVehicleTypes(),
            fetchContents()
        ]).finally(() => setIsLoading(false));
    }, []);

    // Vehicle Types functions
    const fetchVehicleTypes = async () => {
        try {
            const response = await fetch(`${ApiRepos}lookup/vehicletypes`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch vehicle types');
            const data = await response.json();
            setVehicleTypes(data);
        } catch (err) {
            setError(err.message);
            setTimeout(() => setError(null), 3000);
        }
    };

    const handleVehicleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = currentType
                ? `${ApiRepos}lookup/vehicletypes/${currentType.id}`
                : `${ApiRepos}lookup/vehicletypes`;

            const response = await fetch(url, {
                method: currentType ? 'PUT' : 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(vehicleFormData)
            });

            if (!response.ok) throw new Error('Failed to save vehicle type');

            await fetchVehicleTypes();
            setShowVehicleDialog(false);
            setVehicleFormData({ name: '', description: '', carbonEmission: 0 });
            setCurrentType(null);
            setSuccessMessage(currentType ? 'Vehicle type updated successfully' : 'Vehicle type created successfully');
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (err) {
            setError(err.message);
            setTimeout(() => setError(null), 3000);
        }
    };

    const toggleVehicleStatus = async (type) => {
        if (!window.confirm(`Are you sure you want to ${type.isActive ? 'hide' : 'show'} this vehicle type?`)) {
            return;
        }

        try {
            const url = `${ApiRepos}lookup/vehicletypes/${type.id}/toggle-status`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(!type.isActive)
            });

            if (!response.ok) throw new Error(`Failed to ${type.isActive ? 'hide' : 'show'} vehicle type`);

            await fetchVehicleTypes();
            setSuccessMessage(`Vehicle type ${type.isActive ? 'hidden' : 'shown'} successfully`);
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (err) {
            setError(err.message);
            setTimeout(() => setError(null), 3000);
        }
    };

    // Content Management functions
    const fetchContents = async () => {
        try {
            const response = await fetch(`${ApiRepos}contentmanagement`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch content');
            const data = await response.json();
            setContents(data);
        } catch (err) {
            setError(err.message);
            setTimeout(() => setError(null), 3000);
        }
    };

    const handleContentSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = currentContent
                ? `${ApiRepos}contentmanagement/${currentContent.id}`
                : `${ApiRepos}contentmanagement`;

            const response = await fetch(url, {
                method: currentContent ? 'PUT' : 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(contentFormData)
            });

            if (!response.ok) throw new Error('Failed to save content');

            await fetchContents();
            setShowContentDialog(false);
            setContentFormData({ locationId: '', content: '' });
            setCurrentContent(null);
            setSuccessMessage(currentContent ? 'Content updated successfully' : 'Content created successfully');
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (err) {
            setError(err.message);
            setTimeout(() => setError(null), 3000);
        }
    };

    const toggleContentStatus = async (content) => {
        if (!window.confirm(`Are you sure you want to ${content.isActive ? 'deactivate' : 'activate'} this content?`)) {
            return;
        }

        try {
            const response = await fetch(`${ApiRepos}contentmanagement/${content.id}/toggle`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Failed to toggle content status');

            await fetchContents();
            setSuccessMessage('Content status updated successfully');
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (err) {
            setError(err.message);
            setTimeout(() => setError(null), 3000);
        }
    };

    //const handlePreviewContent = (content) => {
    //    const previewWindow = window.open('', '_blank');
    //    previewWindow.document.write(`
    //        <!DOCTYPE html>
    //        <html>
    //            <head>
    //                <title>Content Preview</title>
    //                <meta charset="UTF-8">
    //                <meta name="viewport" content="width=device-width, initial-scale=1.0">
    //                <style>
    //                    body { 
    //                        font-family: Arial, sans-serif;
    //                        line-height: 1.6;
    //                        padding: 20px;
    //                        max-width: 800px;
    //                        margin: 0 auto;
    //                    }
    //                </style>
    //            </head>
    //            <body>
    //                ${content}
    //            </body>
    //        </html>
    //    `);
    //    previewWindow.document.close();
    //};

    if (isLoading) return <div className="settings-page-loading">Loading...</div>;

    return (
        <div className="settings-container">
            <div className="settings-header">
                <h1>Settings</h1>
            </div>

            {error && <div className="settings-page-error">{error}</div>}
            {successMessage && <div className="settings-page-success">{successMessage}</div>}

            {/* Vehicle Types Section */}
            <div className="settings-section">
                <div className="settings-section-header">
                    <h2>Vehicle Types</h2>
                    <button
                        className="action-button add-new"
                        onClick={() => {
                            setVehicleFormData({ name: '', description: '', carbonEmission: 0 });
                            setCurrentType(null);
                            setShowVehicleDialog(true);
                        }}
                    >
                        Add Vehicle Type
                    </button>
                </div>
                <div className="settings-page-content">
                    <table className="settings-page-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Carbon Emission (g/km)</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vehicleTypes.map(type => (
                                <tr key={type.id} className={!type.isActive ? 'settings-page-inactive-row' : ''}>
                                    <td>{type.name}</td>
                                    <td>{type.description}</td>
                                    <td>{type.carbonEmission}</td>
                                    <td>
                                        <span className={`settings-page-status ${type.isActive ? 'active' : 'inactive'}`}>
                                            {type.isActive ? 'Shown' : 'Hidden'}
                                        </span>
                                    </td>
                                    <td className="settings-page-actions">
                                        <button
                                            className="settings-page-edit-button"
                                            onClick={() => {
                                                setVehicleFormData({
                                                    name: type.name,
                                                    description: type.description,
                                                    carbonEmission: type.carbonEmission
                                                });
                                                setCurrentType(type);
                                                setShowVehicleDialog(true);
                                            }}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className={type.isActive ? 'settings-page-hide-button' : 'settings-page-show-button'}
                                            onClick={() => toggleVehicleStatus(type)}
                                        >
                                            {type.isActive ? 'Hide' : 'Show'}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Content Management Section */}
            <div className="settings-section">
                <div className="settings-section-header">
                    <h2>Content Management</h2>
                    <button
                        className="action-button add-new"
                        onClick={() => {
                            setContentFormData({ locationId: '', content: '' });
                            setCurrentContent(null);
                            setShowContentDialog(true);
                        }}
                    >
                        Add Content
                    </button>
                </div>
                <div className="settings-page-content">
                    <table className="settings-page-table">
                        <thead>
                            <tr>
                                <th>Location</th>
                                <th>Content Preview</th>
                                <th>Last Updated</th>
                                <th>Updated By</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contents.map(content => (
                                <tr key={content.id} className={!content.isActive ? 'settings-page-inactive-row' : ''}>
                                    <td>{locationOptions.find(loc => loc.id === content.locationId)?.label}</td>
                                    <td>
                                        <div className="content-preview">
                                            {content.content.replace(/<[^>]*>?/gm, '').substring(0, 100)}...
                                            {/*<button*/}
                                            {/*    className="preview-button"*/}
                                            {/*    onClick={() => handlePreviewContent(content.content)}*/}
                                            {/*>*/}
                                            {/*    Preview*/}
                                            {/*</button>*/}
                                        </div>
                                    </td>
                                    <td className="date-column">
                                        {content.updatedDate ? new Date(content.updatedDate).toLocaleString() : 'Never'}
                                    </td>
                                    <td className="user-column">{content.lastUpdatedByUserName}</td>
                                    <td>
                                        <span className={`settings-page-status ${content.isActive ? 'active' : 'inactive'}`}>
                                            {content.isActive ? 'Active' : 'Inactive'}
                                        </span>
                                    </td>
                                    <td className="settings-page-actions">
                                        <button
                                            className="settings-page-edit-button"
                                            onClick={() => {
                                                setContentFormData({
                                                    locationId: content.locationId,
                                                    content: content.content
                                                });
                                                setCurrentContent(content);
                                                setShowContentDialog(true);
                                            }}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className={content.isActive ? 'settings-page-hide-button' : 'settings-page-show-button'}
                                            onClick={() => toggleContentStatus(content)}
                                        >
                                            {content.isActive ? 'Deactivate' : 'Activate'}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Vehicle Type Modal */}
            {showVehicleDialog && (
                <div className="settings-page-modal-overlay">
                    <div className="settings-page-modal">
                        <h2>{currentType ? 'Edit Vehicle Type' : 'Add Vehicle Type'}</h2>
                        <form onSubmit={handleVehicleSubmit}>
                            <div className="settings-page-form-group">
                                <label>Name *</label>
                                <input
                                    type="text"
                                    value={vehicleFormData.name}
                                    onChange={(e) => setVehicleFormData(prev => ({
                                        ...prev,
                                        name: e.target.value
                                    }))}
                                    required
                                    maxLength={100}
                                />
                            </div>
                            <div className="settings-page-form-group">
                                <label>Description</label>
                                <textarea
                                    value={vehicleFormData.description}
                                    onChange={(e) => setVehicleFormData(prev => ({
                                        ...prev,
                                        description: e.target.value
                                    }))}
                                    maxLength={500}
                                    rows={4}
                                />
                            </div>
                            <div className="settings-page-form-group">
                                <label>Carbon Emission (g/km) *</label>
                                <input
                                    type="number"
                                    value={vehicleFormData.carbonEmission}
                                    onChange={(e) => setVehicleFormData(prev => ({
                                        ...prev,
                                        carbonEmission: parseFloat(e.target.value) || 0
                                    }))}
                                    required
                                    min="0"
                                    step="0.1"
                                />
                            </div>
                            <div className="settings-page-modal-actions">
                                <button
                                    type="button"
                                    className="settings-page-cancel-button"
                                    onClick={() => setShowVehicleDialog(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="settings-page-submit-button"
                                >
                                    {currentType ? 'Update' : 'Create'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Content Management Modal */}
            {showContentDialog && (
                <div className="settings-page-modal-overlay">
                    <div className="settings-page-modal content-modal">
                        <h2>{currentContent ? 'Edit Content' : 'Add Content'}</h2>
                        <form onSubmit={handleContentSubmit}>
                            <div className="settings-page-form-group">
                                <label>Location *</label>
                                <select
                                    value={contentFormData.locationId}
                                    onChange={(e) => setContentFormData(prev => ({
                                        ...prev,
                                        locationId: e.target.value
                                    }))}
                                    required
                                    disabled={currentContent}
                                >
                                    <option value="">Select a location</option>
                                    {locationOptions.map(location => (
                                        <option key={location.id} value={location.id}>
                                            {location.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="settings-page-form-group">
                                <label>Content *</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={contentFormData.content}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setContentFormData(prev => ({
                                            ...prev,
                                            content: data
                                        }));
                                    }}
                                    config={{
                                        toolbar: [
                                            'heading',
                                            '|',
                                            'bold',
                                            'italic',
                                            'link',
                                            'bulletedList',
                                            'numberedList',
                                            '|',
                                            'outdent',
                                            'indent',
                                            '|',
                                            'blockQuote',
                                            'insertTable',
                                            'undo',
                                            'redo'
                                        ]
                                    }}
                                />
                            </div>
                            <div className="settings-page-modal-actions">
                                {/*<button*/}
                                {/*    type="button"*/}
                                {/*    className="settings-page-preview-button"*/}
                                {/*    onClick={() => handlePreviewContent(contentFormData.content)}*/}
                                {/*>*/}
                                {/*    Preview*/}
                                {/*</button>*/}
                                <button
                                    type="button"
                                    className="settings-page-cancel-button"
                                    onClick={() => setShowContentDialog(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="settings-page-submit-button"
                                >
                                    {currentContent ? 'Update' : 'Create'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;