import React, { useState } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useAuth } from '../../../auth/AuthProvider';
import { USER_LEVELS } from '../../../auth/constants';
import './BusinessProfile.css';

const BusinessAddress = ({ address, onAddressUpdated, onAddressDeleted }) => {
    const { checkPermission } = useAuth();
    const [isEditing, setIsEditing] = useState(!address.id); // Start in edit mode if it's a new address
    const [editedAddress, setEditedAddress] = useState(address);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const isAdmin = checkPermission(USER_LEVELS.ADMIN);
    const isManager = checkPermission(USER_LEVELS.BUSINESS_MANAGER);
    //const isUser = checkPermission(USER_LEVELS.BUSINESS_USER);

    const handleEdit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const method = address.id ? 'PUT' : 'POST';
            const url = address.id
                ? `${ApiRepos}business/address/${address.id}`
                : `${ApiRepos}business/address`;

            // Remove id from request body if it's null (for new addresses)
            const addressData = { ...editedAddress };
            if (!addressData.id) {
                delete addressData.id;
            }

            console.log('Sending address data:', addressData); // For debugging

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(addressData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to save address');
            }

            const updatedAddress = await response.json();
            onAddressUpdated(updatedAddress);
            setIsEditing(false);
        } catch (err) {
            console.error('Error saving address:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            const confirmDelete = window.confirm('Are you sure you want to delete this address?');
            if (!confirmDelete) {
                return; // Just return if cancelled, don't call onAddressDeleted
            }

            setLoading(true);
            setError(null);

            const response = await fetch(`${ApiRepos}business/address/${address.id}`, {
                method: 'DELETE',
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Failed to delete address');

            onAddressDeleted(address.id);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div className="address-card loading">Loading...</div>;

    if (isEditing) {
        return (
            <div className="address-card editing">
                <form onSubmit={handleEdit}>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Address Line 1 *</label>
                            <input
                                type="text"
                                value={editedAddress.addressLine1}
                                onChange={(e) => setEditedAddress({ ...editedAddress, addressLine1: e.target.value })}
                                required
                                maxLength={100}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Address Line 2</label>
                            <input
                                type="text"
                                value={editedAddress.addressLine2 || ''}
                                onChange={(e) => setEditedAddress({ ...editedAddress, addressLine2: e.target.value })}
                                maxLength={100}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Address Line 3</label>
                            <input
                                type="text"
                                value={editedAddress.addressLine3 || ''}
                                onChange={(e) => setEditedAddress({ ...editedAddress, addressLine3: e.target.value })}
                                maxLength={100}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Town *</label>
                            <input
                                type="text"
                                value={editedAddress.town}
                                onChange={(e) => setEditedAddress({ ...editedAddress, town: e.target.value })}
                                required
                                maxLength={100}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>County</label>
                            <input
                                type="text"
                                value={editedAddress.county || ''}
                                onChange={(e) => setEditedAddress({ ...editedAddress, county: e.target.value })}
                                maxLength={100}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Post Code *</label>
                            <input
                                type="text"
                                value={editedAddress.postCode}
                                onChange={(e) => setEditedAddress({ ...editedAddress, postCode: e.target.value })}
                                required
                                maxLength={10}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Landline Number</label>
                            <input
                                type="tel"
                                value={editedAddress.landlineNumber || ''}
                                onChange={(e) => setEditedAddress({ ...editedAddress, landlineNumber: e.target.value })}
                                maxLength={20}
                            />
                        </div>
                    </div>

                    {error && <div className="error-message">{error}</div>}

                    <div className="form-actions">
                        <button type="submit" className="action-button submit-button" disabled={loading}>
                            {!address.id ? 'Save Address' : 'Save Changes'}
                        </button>
                    </div>
                </form>
            </div>
        );
    }

    return (
        <div className="address-card flex flex-col min-h-[200px]">
            <div className="flex-grow">
                <p>{address.addressLine1}</p>
                {address.addressLine2 && <p>{address.addressLine2}</p>}
                {address.addressLine3 && <p>{address.addressLine3}</p>}
                <p>{address.town}</p>
                {address.county && <p>{address.county}</p>}
                <p>{address.postCode}</p>
                {address.landlineNumber && <p>Tel: {address.landlineNumber}</p>}

                {error && <div className="error-message">{error}</div>}
            </div>
            <div className="card-actions mt-auto pt-4">
                {(isAdmin || isManager) && (
                    <button
                        onClick={() => setIsEditing(true)}
                        className="action-button edit-button"
                        disabled={loading}
                        type="button"
                    >
                        Edit
                    </button>
                )}
                {(isAdmin || isManager) && (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDelete();
                        }}
                        className="action-button cancel-button"
                        disabled={loading}
                        type="button"
                    >
                        Delete
                    </button>
                )}
            </div>
        </div>
    );
};

export default BusinessAddress;
