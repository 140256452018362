export const USER_LEVELS = {
    BUSINESS_USER: 1,
    BUSINESS_MANAGER: 2,
    ADMIN: 9
};

export const USER_LEVEL_LABELS = {
    [USER_LEVELS.BUSINESS_USER]: 'Business User',
    [USER_LEVELS.BUSINESS_MANAGER]: 'Business Manager',
    [USER_LEVELS.ADMIN]: 'SMC Administrator'
};