import React from 'react';

const Footer = () => {
    return (
        <>
            <footer className="footer bg-lighter footer--centered py-6 py-xl-8" style={{ overflowX: "hidden" }}>
                <div>
                    <div className="container-lg d-flex flex-column align-items-center gap-5 gap-lg-6">
                        <div slot="one">
                            <bsk-footer-logo img-src={'../assets/images/motorent.jpg'} img-alt="logo" img-width="173" img-height="50" className="d-block mb-lg-3" bsk-advanced="">
                                <a href="/">
                                    <img className="mb-0" src={'../assets/images/motorent.jpg'} alt="logo"/>
                                </a>
                            </bsk-footer-logo>
                        </div>
                        <div slot="two">
                            <bsk-footer-content is="centered-links" bsk-advanced="">
                            <div className="umb-grid">
                                <div className="grid-section">
                                    <div className="py-0">
                                        <div className="container">
                                        <div className="row c">
                                            <div className="col-md-12 column">
                                                <div>
                                                    <div className="advanced__textarea">
                                                    <ul>
                                                                    <li className="mb-0"><a href="https://www.motorent.biz/terms-and-conditions/" target="motorent">Terms &amp; Conditions</a></li>
                                                                    <li className="mb-0"><a href="https://www.motorent.biz/cookies/" target="motorent">Cookie Policy</a></li>
                                                                    <li className="mb-0"><a href="https://www.motorent.biz/privacy-policy/" target="motorent">Privacy Policy</a></li>
                                                                    <li className="mb-0"><a href="https://www.motorent.biz/sitemap/" target="motorent">Sitemap</a></li>
                                                        <li className="mb-0"><a rel="noopener" href="https://bluesky-cogcms.cdn.imgeng.in/media/zujjxdba/rental-agreement-terms-and-condition.pdf" target="motorent">Rental Terms And Conditions</a></li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer__content">
                                <slot name="content"></slot>
                            </div>
                            </bsk-footer-content>
                        </div>
                        <div slot="three">
                            <div className="umb-grid">
                                <div className="grid-section">
                                    <div className="py-0">
                                        <div className="container">
                                            <div className="row c">
                                            <div className="col-md-12 column">
                                                <div>
                                                    <div className="advanced__textarea">
                                                        <p className="text-center">Registered address: Unit B2, Endeavour Business Park, Penner Road, Havant, Hampshire PO9 1QN, United Kingdom. Company House No:02267102. ICO Registration: Z1753133.</p>
                                                        <p className="text-center mb-0">We are authorised and regulated by the Financial Conduct Authority for credit brokerage. FRN 727319. We are a credit broker not a lender.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div slot="four">
                            <div>
                            <p className="my-0 text-center">© 2024 Motorent</p>
                            <div>
                                    <a href="https://www.bvrla.co.uk/membership/codes-of-conduct.html" className="nav-link" target="motorent"><img src={'../assets/images/bvrla.jpg'} alt="BVRLA" loading="lazy" width="94" height="40" className="h-auto mx-auto d-block mt-4" /></a>
                            </div>
                            </div>
                        </div>
                        <div slot="bottom">
                            <ul className="list-unstyled d-flex gap-4 justify-content-center mb-0 align-items-center">
                            <li className="nav-item h5 mb-0">
                                    <a className="nav-link" target="motorent" href="https://www.facebook.com/MotorentHavant/">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li className="nav-item h5 mb-0">
                                    <a className="nav-link" target="motorent" href="https://www.linkedin.com/company/95740927/admin/feed/posts/">
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                            </li>
                            <li className="nav-item h5 mb-0">
                                    <a className="nav-link" target="motorent" href="https://www.instagram.com/motorent/">
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <slot name="bottom"></slot>
            </footer>
        </>
    );
};

export default Footer;