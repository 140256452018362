import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import BusinessAddress from './BusinessAddress';
import { useAuth } from '../../../auth/AuthProvider';
import { USER_LEVELS } from '../../../auth/constants';

const BusinessProfile = () => {
    const { businessId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { user, checkPermission } = useAuth();

    const [businessData, setBusinessData] = useState({
        businessName: '',
        vatNumber: '',
        website: '',
        registrationCode: '',
        onHold: false
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [showAddAddressForm, setShowAddAddressForm] = useState(false);
    const [addresses, setAddresses] = useState([]);

    // Permission checks
    const isAdmin = checkPermission(USER_LEVELS.ADMIN);
    const isManager = checkPermission(USER_LEVELS.BUSINESS_MANAGER);

    useEffect(() => {
        if (location.pathname === '/dashboard/business-profile') {
            if (!user?.businessId) {
                navigate('/dashboard');
            }
            return;
        }

        if (location.pathname.includes('/dashboard/business/')) {
            if (!isAdmin) {
                navigate('/dashboard');
                return;
            }
        }
    }, [isAdmin, user?.businessId, location.pathname, navigate]);

    useEffect(() => {
        const fetchBusinessData = async () => {
            try {
                setLoading(true);
                setError(null);

                const targetBusinessId = location.pathname === '/dashboard/business-profile'
                    ? user.businessId
                    : businessId;

                if (!targetBusinessId) {
                    throw new Error("Invalid business ID");
                }

                const businessResponse = await fetch(`${ApiRepos}business/${targetBusinessId}`, {
                    credentials: 'include'
                });

                if (!businessResponse.ok) {
                    throw new Error('Failed to fetch business details');
                }

                const businessDetails = await businessResponse.json();
                setBusinessData(businessDetails);

                const addressesResponse = await fetch(`${ApiRepos}business/${targetBusinessId}/addresses`, {
                    credentials: 'include'
                });

                if (!addressesResponse.ok) {
                    throw new Error('Failed to fetch addresses');
                }

                const addressesData = await addressesResponse.json();
                setAddresses(addressesData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinessData();
    }, [businessId, user?.businessId, location.pathname]);


    const handleBusinessSubmit = async (e) => {
        e.preventDefault();
        if (!isManager) return;

        setError(null);
        setSuccessMessage('');

        try {
            if (!businessData.businessName) {
                throw new Error('Business name is required');
            }

            // Get the correct business ID based on the path
            const targetBusinessId = location.pathname === '/dashboard/business-profile'
                ? user.businessId
                : businessId;

            const response = await fetch(`${ApiRepos}business/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    ...businessData,
                    id: targetBusinessId
                })
            });

            if (!response.ok) {
                throw new Error('Failed to save business profile');
            }

            const savedBusiness = await response.json();
            setBusinessData(savedBusiness);
            setSuccessMessage('Business profile saved successfully');
        } catch (err) {
            setError(err.message);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const handleAddressAdded = async (addressData) => {
        try {
            const response = await fetch(`${ApiRepos}business/address`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(addressData),
            });

            if (!response.ok) throw new Error('Failed to add address');

            const newAddress = await response.json();

            // Ensure the new address includes all necessary fields
            const formattedAddress = {
                ...newAddress,
                addressLine2: newAddress.addressLine2 || '',
                addressLine3: newAddress.addressLine3 || '',
                county: newAddress.county || '',
                landlineNumber: newAddress.landlineNumber || '',
            };

            setAddresses((prev) => [...prev, formattedAddress]); // This should trigger rerender
            setSuccessMessage('Address added successfully');
            setShowAddAddressForm(false);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleAddressUpdated = (updatedAddress) => {
        setAddresses(prevAddresses =>
            prevAddresses.map(address =>
                address.id === updatedAddress.id ? updatedAddress : address
            )
        );
        setSuccessMessage('Address updated successfully');
    };

    const handleAddressDeleted = (deletedAddressId) => {
        setAddresses(prevAddresses =>
            prevAddresses.filter(address => address.id !== deletedAddressId)
        );
        setSuccessMessage('Address deleted successfully');
    };

    if (loading) {
        return <div className="flex justify-center items-center min-h-[400px]">Loading...</div>;
    }

    return (
        <div className="business-profile-container">
            <div className="business-form-wrapper">
                <h2 className="business-title">Business Profile</h2>

                {error && (
                    <div className="error-message mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
                        {error}
                    </div>
                )}

                {successMessage && (
                    <div className="success-message mb-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded">
                        {successMessage}
                    </div>
                )}

                {/* Separate form for business profile */}
                <form onSubmit={handleBusinessSubmit} className="business-form">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group">
                                <label className="required">Business Name</label>
                                <input
                                    type="text"
                                    value={businessData.businessName || ''}
                                    onChange={(e) => setBusinessData({ ...businessData, businessName: e.target.value })}
                                    disabled={!isManager}
                                    className={!isManager ? 'bg-gray-100' : ''}
                                />
                            </div>
                            <div className="form-group">
                                <label>VAT Number</label>
                                <input
                                    type="text"
                                    value={businessData.vatNumber || ''}
                                    onChange={(e) => setBusinessData({ ...businessData, vatNumber: e.target.value })}
                                    disabled={!isManager}
                                    className={!isManager ? 'bg-gray-100' : ''}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <label>Website</label>
                                <input
                                    type="url"
                                    value={businessData.website || ''}
                                    onChange={(e) => setBusinessData({ ...businessData, website: e.target.value })}
                                    disabled={!isManager}
                                    className={!isManager ? 'bg-gray-100' : ''}
                                />
                            </div>
                        </div>

                        {(isManager) && (
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Registration Code</label>
                                    {isManager && !businessData.registrationCode ? (
                                        <div className="text-sm text-gray-600 mt-1">
                                            Please request a registration code from SMC staff
                                        </div>
                                    ) : (
                                        <input
                                            type="text"
                                            value={businessData.registrationCode || ''}
                                            disabled={true}
                                            className="bg-gray-100"
                                        />
                                    )}
                                </div>
                            </div>
                        )}

                        {isManager && (
                            <button
                                type="submit"
                                className="dashboard-button"
                                disabled={!businessData.businessName}
                            >
                                Update Business Profile
                            </button>
                        )}
                    </div>
                </form>

                {/* Separate section for addresses - outside the main form */}
                <div className="form-section mt-4">
                    <h3 className="text-xl font-semibold mb-4">Business Addresses</h3>
                    {isManager && (
                        <>
                            <button
                                type="button"
                                className="dashboard-button"
                                onClick={() => setShowAddAddressForm(!showAddAddressForm)}
                            >
                                {showAddAddressForm ? 'Cancel Adding Address' : 'Add New Address'}
                            </button>
                            <br /><br />
                        </>
                    )}
                    {showAddAddressForm && (
                        <div className="new-address-form">
                            <div className="flex justify-between items-center mb-4">
                                <h4>Add New Address</h4>
                            </div>
                            <BusinessAddress
                                address={{
                                    id: null,
                                    addressLine1: '',
                                    addressLine2: '',
                                    addressLine3: '',
                                    town: '',
                                    county: '',
                                    postCode: '',
                                    landlineNumber: '',
                                    businessId: location.pathname === '/dashboard/business-profile'
                                        ? user.businessId
                                        : parseInt(businessId),
                                    isActive: true
                                }}
                                onAddressUpdated={handleAddressAdded}
                                onAddressDeleted={() => setShowAddAddressForm(false)}
                            />
                        </div>
                    )}
                    <div className="addresses-grid">
                        {addresses.map(address => (
                            <BusinessAddress
                                key={address.id}
                                address={address}
                                onAddressUpdated={handleAddressUpdated}
                                onAddressDeleted={handleAddressDeleted}
                                isAdmin={isManager}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessProfile;