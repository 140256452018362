import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';
import { USER_LEVELS } from '../auth/constants';

export const ProtectedRoute = ({
    children,
    requiredLevel = USER_LEVELS.BUSINESS_USER
}) => {
    const { user, loading, initialized, checkPermission } = useAuth();
    const location = useLocation();

    if (!initialized || loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return (
            <Navigate
                to="/"
                state={{
                    from: location,
                    message: 'Please log in to access this page.'
                }}
                replace
            />
        );
    }

    if (!checkPermission(requiredLevel)) {
        return (
            <Navigate
                to="/dashboard"
                state={{
                    message: 'You do not have permission to access this page.'
                }}
                replace
            />
        );
    }

    return children;
};

export default ProtectedRoute;