import React, { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import DashboardHeader from './components/DashboardHeader';
import DashboardFooter from './components/DashboardFooter';
import Sidebar from './components/Sidebar';
import DashboardHome from './home/DashboardHome';
import QuoteRequest from './order/QuoteRequest';
import OrderVehicle from './order/OrderVehicle';
import AccidentSupport from './accident-support/AccidentSupport';
import FAQs from './faqs/FAQs';
import UserProfile from './profile/UserProfile';
import BusinessProfile from './business-profile/BusinessProfile';
import ManageUsers from './manage-users/ManageUsers';

import ViewQuotes from './quotes/ViewQuotes';
import ViewOrders from './orders/ViewOrders';
import ViewQuote from './quotes/ViewQuote';
import ViewOrder from './orders/ViewOrder';
import AccidentReports from './accidents/AccidentReports';
import BusinessProfiles from './business-profiles/BusinessProfiles';
import Settings from './settings/Settings';
import Reporting from './reporting/Reporting';

import { useAuth } from '../../auth/AuthProvider';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import './Dashboard.css';

const Dashboard = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { user } = useAuth();

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className='dashboard-page'>
            <DashboardHeader
                toggleSidebar={toggleSidebar}
                opened={sidebarOpen}
                userData={user}
            />
            <div className="dashboard-main">
                <Sidebar toggleSidebar={toggleSidebar} opened={sidebarOpen} />
                <div className="dashboard-page-content">
                    <Routes>
                        {/* Keep all base routes the same */}
                        <Route path="/" element={<Navigate to="home" replace />} />
                        <Route path="home" element={<DashboardHome />} />
                        <Route path="quote" element={<QuoteRequest />} />
                        <Route path="order" element={<OrderVehicle />} />
                        <Route path="accident-support" element={<AccidentSupport />} />
                        <Route path="accident-support/:id" element={<AccidentSupport />} />
                        <Route path="accident-reports" element={<AccidentReports />} />
                        <Route path="faqs" element={<FAQs />} />
                        <Route path="profile" element={<UserProfile />} />
                        <Route path="business-profile" element={<BusinessProfile />} />
                        <Route path="quotes" element={<ViewQuotes />} />
                        <Route path="quotes/:id" element={<ViewQuote />} />
                        <Route path="orders" element={<ViewOrders />} />
                        <Route path="orders/:id" element={<ViewOrder />} />


                        {/* Add the missing business routes */}
                        <Route path="business/:businessId" element={
                            <ProtectedRoute requiredLevel={9}>
                                <BusinessProfile />
                            </ProtectedRoute>
                        } />
                        <Route path="business/new" element={
                            <ProtectedRoute requiredLevel={9}>
                                <BusinessProfile />
                            </ProtectedRoute>
                        } />

                        {/* Only add ProtectedRoute wrapper for elevated permission routes */}
                        <Route path="manage-users" element={
                            <ProtectedRoute requiredLevel={2}>
                                <ManageUsers />
                            </ProtectedRoute>
                        } />
                        <Route path="business-profiles" element={
                            <ProtectedRoute requiredLevel={9}>
                                <BusinessProfiles />
                            </ProtectedRoute>
                        } />
                        <Route path="reporting" element={
                            <ProtectedRoute requiredLevel={9}>
                                <Reporting />
                            </ProtectedRoute>
                        } />
                        <Route path="settings" element={
                            <ProtectedRoute requiredLevel={9}>
                                <Settings />
                            </ProtectedRoute>
                        } />
                    </Routes>
                    <DashboardFooter />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;