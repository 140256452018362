import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import { TimeSelector } from './TimeSelector';
import { validateDateTime, getMinDateTime } from './dateTimeUtils';
import { toast } from 'react-toastify';
import './OrderVehicle.css';

const initialDateTime = getMinDateTime();

const OrderVehicle = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const editOrderId = searchParams.get('edit');
    const isEditMode = !!editOrderId;

    const [formData, setFormData] = useState({
        isDriver: false,
        driverName: '',
        driverEmail: '',
        mobile: '',
        vehicleType: '',
        poNumber: '',
        logistics: '',
        addressLine1: '',
        addressLine2: '',
        town: '',
        postCode: '',
        notes: '',
        purpose: '',
        hireFromDate: initialDateTime.date,
        hireFromHour: initialDateTime.hour,
        hireFromMinute: initialDateTime.minute,
        hireToDate: initialDateTime.date,
        hireToHour: (parseInt(initialDateTime.hour) + 1).toString().padStart(2, '0'),
        hireToMinute: initialDateTime.minute
    });

    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [businessAddresses, setBusinessAddresses] = useState([]);
    const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [orderFormNotes, setOrderFormNotes] = useState([]);

    const validateUKMobile = (phone) => {
        const regex = /^(?:(?:\+44)|(?:0))7\d{9}$/;
        return regex.test(phone.replace(/\s+/g, ''));
    };

    const handleFromDateChange = (newFromDate) => {
        setFormData(prev => {
            const fromDateTime = new Date(`${newFromDate}T${prev.hireFromHour}:${prev.hireFromMinute}`);
            const toDateTime = new Date(`${prev.hireToDate}T${prev.hireToHour}:${prev.hireToMinute}`);

            if (fromDateTime >= toDateTime) {
                const newToDateTime = new Date(fromDateTime);
                newToDateTime.setHours(fromDateTime.getHours() + 1);

                return {
                    ...prev,
                    hireFromDate: newFromDate,
                    hireToDate: newToDateTime.toISOString().split('T')[0],
                    hireToHour: newToDateTime.getHours().toString().padStart(2, '0'),
                    hireToMinute: newToDateTime.getMinutes().toString().padStart(2, '0')
                };
            }
            return { ...prev, hireFromDate: newFromDate };
        });
    };

    const handleFromTimeChange = (type, value) => {
        setFormData(prev => {
            const newFormData = { ...prev, [type]: value };
            const fromDateTime = new Date(`${prev.hireFromDate}T${type === 'hireFromHour' ? value : prev.hireFromHour}:${type === 'hireFromMinute' ? value : prev.hireFromMinute}`);
            const toDateTime = new Date(`${prev.hireToDate}T${prev.hireToHour}:${prev.hireToMinute}`);

            if (fromDateTime >= toDateTime) {
                const newToDateTime = new Date(fromDateTime);
                newToDateTime.setHours(fromDateTime.getHours() + 1);

                return {
                    ...newFormData,
                    hireToDate: newToDateTime.toISOString().split('T')[0],
                    hireToHour: newToDateTime.getHours().toString().padStart(2, '0'),
                    hireToMinute: newToDateTime.getMinutes().toString().padStart(2, '0')
                };
            }
            return newFormData;
        });
    };

    const handleLogisticsChange = useCallback(async (value) => {
        setFormData(prev => ({ ...prev, logistics: value }));
        if (value === 'Delivery') {
            setShowDeliveryAddress(true);
            try {
                const businessId = currentUser?.businessId;
                if (!businessId) return;

                const response = await fetch(`${ApiRepos}business/${businessId}/addresses`, {
                    credentials: 'include'
                });
                if (!response.ok) throw new Error('Failed to fetch addresses');
                const data = await response.json();
                setBusinessAddresses(data);
            } catch (error) {
                console.error('Error fetching business addresses:', error);
                setError('Failed to load delivery addresses');
            }
        } else {
            setShowDeliveryAddress(false);
        }
    }, [currentUser?.businessId]); // Only depend on businessId

    // Split into two effects - one for initial data
    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);
            try {
                // Fetch all initial data in parallel
                const [userResponse, typesResponse, notesResponse] = await Promise.all([
                    fetch(`${ApiRepos}account/current-user`, { credentials: 'include' }),
                    fetch(`${ApiRepos}lookup/vehicletypes/active`, { credentials: 'include' }),
                    fetch(`${ApiRepos}contentmanagement/order-form-bottom`, { credentials: 'include' })
                ]);

                if (!userResponse.ok) throw new Error('Failed to fetch user data');
                if (!typesResponse.ok) throw new Error('Failed to fetch vehicle types');

                const userData = await userResponse.json();
                const typesData = await typesResponse.json();
                setCurrentUser(userData);
                setVehicleTypes(typesData);

                // Handle the notes content separately as it's optional
                if (notesResponse.ok) {
                    const notesData = await notesResponse.json();
                    setOrderFormNotes(notesData);
                }
            } catch (err) {
                setError(err.message);
                console.error('Error fetching initial data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, []);

    // Separate effect for handling edit mode
    useEffect(() => {
        const loadOrderData = async () => {
            if (!isEditMode || !editOrderId) return;

            try {
                setLoading(true);
                const orderResponse = await fetch(`${ApiRepos}order/${editOrderId}`, { credentials: 'include' });
                if (!orderResponse.ok) throw new Error('Failed to fetch order details');
                const orderData = await orderResponse.json();

                const fromDate = new Date(orderData.hireFromDate);
                const toDate = new Date(orderData.hireToDate);

                const newFormData = {
                    isDriver: false,
                    driverName: orderData.driverName,
                    driverEmail: orderData.driverEmail,
                    mobile: orderData.mobile,
                    vehicleType: orderData.vehicleTypeId.toString(),
                    poNumber: orderData.poNumber || '',
                    logistics: orderData.logistics,
                    addressLine1: orderData.addressLine1 || '',
                    addressLine2: orderData.addressLine2 || '',
                    town: orderData.town || '',
                    postCode: orderData.postCode || '',
                    notes: orderData.notes || '',
                    purpose: orderData.purpose || '',
                    hireFromDate: fromDate.toISOString().split('T')[0],
                    hireFromHour: orderData.hireFromHour,
                    hireFromMinute: orderData.hireFromMinute,
                    hireToDate: toDate.toISOString().split('T')[0],
                    hireToHour: orderData.hireToHour,
                    hireToMinute: orderData.hireToMinute
                };

                setFormData(newFormData);

                // Handle logistics after form data is set
                if (orderData.logistics === 'Delivery') {
                    await handleLogisticsChange('Delivery');
                }
            } catch (err) {
                setError(err.message);
                console.error('Error fetching order data:', err);
            } finally {
                setLoading(false);
            }
        };

        loadOrderData();
    }, [editOrderId, isEditMode, handleLogisticsChange]);

    const handleDriverCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setFormData({
            ...formData,
            isDriver: isChecked,
            driverName: isChecked && currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : '',
            driverEmail: isChecked && currentUser ? currentUser.emailAddress : '',
            mobile: isChecked && currentUser ? currentUser.mobileNumber : ''
        });
    };

    const handleAddressSelection = (addressId) => {
        const selected = businessAddresses.find(addr => addr.id === parseInt(addressId));
        if (selected) {
            setFormData({
                ...formData,
                addressLine1: selected.addressLine1,
                addressLine2: selected.addressLine2 || '',
                town: selected.town,
                postCode: selected.postCode
            });
        }
    };

    const validateDateTimes = () => {
        const result = validateDateTime(
            formData.hireFromDate,
            formData.hireFromHour,
            formData.hireFromMinute,
            formData.hireToDate,
            formData.hireToHour,
            formData.hireToMinute
        );

        if (!result.isValid) {
            setError(result.error);
        }

        return result.isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateUKMobile(formData.mobile)) {
            setError('Please enter a valid UK mobile number');
            return;
        }

        if (!validateDateTimes()) {
            return;
        }

        const submitData = {
            driverName: formData.driverName,
            driverEmail: formData.driverEmail,
            mobile: formData.mobile,
            vehicleTypeId: parseInt(formData.vehicleType),
            poNumber: formData.poNumber,
            logistics: formData.logistics,
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            town: formData.town,
            postCode: formData.postCode,
            notes: formData.notes,
            purpose: formData.purpose,
            hireFromDate: formData.hireFromDate,
            hireFromHour: formData.hireFromHour,
            hireFromMinute: formData.hireFromMinute,
            hireToDate: formData.hireToDate,
            hireToHour: formData.hireToHour,
            hireToMinute: formData.hireToMinute
        };

        try {
            const url = isEditMode
                ? `${ApiRepos}order/${editOrderId}`
                : `${ApiRepos}order/order`;

            const method = isEditMode ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(submitData)
            });

            if (!response.ok) {
                throw new Error('Failed to submit order');
            }

            const orderData = await response.json();
            toast.success(isEditMode ? 'Order updated successfully' : 'Order submitted successfully');
            navigate(`/dashboard/orders/${orderData.id}`);
        } catch (err) {
            toast.error('Failed to submit order: ' + err.message);
        }
    };

    if (loading) return <div className="qr-loading">Loading...</div>;
    if (error) return <div className="qr-error">{error}</div>;

    return (
        <div className="qr-container">
            <div className="qr-form-wrapper">
                <h2 className="qr-title">{isEditMode ? `Update Order Ref ${editOrderId}` : 'Order Vehicle'}</h2>
                <form onSubmit={handleSubmit} className="qr-form">
                    <div className="qr-form-section">
                        {/* Driver checkbox and details section */}
                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label className="qr-checkbox-label">
                                    <input
                                        type="checkbox"
                                        checked={formData.isDriver}
                                        onChange={handleDriverCheckboxChange}
                                        className="qr-checkbox-input"
                                    />
                                    Tick if you will be the driver
                                </label>
                            </div>
                        </div>

                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label>Driver Name *</label>
                                <input
                                    type="text"
                                    value={formData.driverName}
                                    onChange={(e) => setFormData({ ...formData, driverName: e.target.value })}
                                    required
                                    placeholder="Enter driver name"
                                    disabled={formData.isDriver}
                                />
                            </div>
                            <div className="qr-form-group">
                                <label>Driver Email *</label>
                                <input
                                    type="email"
                                    value={formData.driverEmail}
                                    onChange={(e) => setFormData({ ...formData, driverEmail: e.target.value })}
                                    required
                                    placeholder="Enter driver email"
                                    disabled={formData.isDriver}
                                />
                            </div>
                            <div className="qr-form-group">
                                <label>Mobile Number *</label>
                                <input
                                    type="tel"
                                    value={formData.mobile}
                                    onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                                    required
                                    placeholder="+44 7XXX XXX XXX"
                                    disabled={formData.isDriver}
                                />
                            </div>
                        </div>

                        {/* Vehicle and PO section */}
                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label>Vehicle Type *</label>
                                <select
                                    value={formData.vehicleType}
                                    onChange={(e) => setFormData({ ...formData, vehicleType: e.target.value })}
                                    required
                                >
                                    <option value="">Select vehicle type...</option>
                                    {vehicleTypes.map(type => (
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="qr-form-group">
                                <label>PO Number</label>
                                <input
                                    type="text"
                                    value={formData.poNumber}
                                    onChange={(e) => setFormData({ ...formData, poNumber: e.target.value })}
                                    placeholder="PO number if available"
                                />
                            </div>
                        </div>

                        {/* Updated dates and times section */}
                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label>Hire From *</label>
                                <div className="date-time-group">
                                    <input
                                        type="date"
                                        value={formData.hireFromDate}
                                        onChange={(e) => handleFromDateChange(e.target.value)}
                                        min={initialDateTime.date}
                                        required
                                        className="qr-date-input"
                                    />
                                    <TimeSelector
                                        selectedHour={formData.hireFromHour}
                                        selectedMinute={formData.hireFromMinute}
                                        onHourChange={(value) => handleFromTimeChange('hireFromHour', value)}
                                        onMinuteChange={(value) => handleFromTimeChange('hireFromMinute', value)}
                                        date={formData.hireFromDate}
                                        minDate={initialDateTime.date}
                                        minHour={initialDateTime.hour}
                                        minMinute={initialDateTime.minute}
                                    />
                                </div>
                            </div>
                            <div className="qr-form-group">
                                <label>Hire To *</label>
                                <div className="date-time-group">
                                    <input
                                        type="date"
                                        value={formData.hireToDate}
                                        onChange={(e) => setFormData({ ...formData, hireToDate: e.target.value })}
                                        min={formData.hireFromDate}
                                        required
                                        className="qr-date-input"
                                    />
                                    <TimeSelector
                                        selectedHour={formData.hireToHour}
                                        selectedMinute={formData.hireToMinute}
                                        onHourChange={(value) => setFormData({ ...formData, hireToHour: value })}
                                        onMinuteChange={(value) => setFormData({ ...formData, hireToMinute: value })}
                                        date={formData.hireToDate}
                                        minDate={formData.hireFromDate}
                                        minHour={formData.hireFromHour}
                                        minMinute={formData.hireFromMinute}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label>Logistics *</label>
                                <select
                                    value={formData.logistics}
                                    onChange={(e) => handleLogisticsChange(e.target.value)}
                                    required
                                >
                                    <option value="">Select logistics option...</option>
                                    <option value="Collect">Collect from SMC office</option>
                                    <option value="Delivery">Delivery</option>
                                </select>
                            </div>
                        </div>

                        {/* Delivery address fields integrated in the same section */}
                        {showDeliveryAddress && (
                            <>
                                <div className="qr-form-row">
                                    <div className="qr-form-group">
                                        <label>Select Existing Address</label>
                                        <select
                                            onChange={(e) => handleAddressSelection(e.target.value)}
                                        >
                                            <option value="">Select an existing address or enter new</option>
                                            {businessAddresses.map(addr => (
                                                <option key={addr.id} value={addr.id}>
                                                    {addr.addressLine1}, {addr.town}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="qr-form-row">
                                    <div className="qr-form-group">
                                        <label>Address Line 1 *</label>
                                        <input
                                            type="text"
                                            value={formData.addressLine1}
                                            onChange={(e) => setFormData({ ...formData, addressLine1: e.target.value })}
                                            required
                                            placeholder="Enter address line 1"
                                        />
                                    </div>
                                    <div className="qr-form-group">
                                        <label>Address Line 2</label>
                                        <input
                                            type="text"
                                            value={formData.addressLine2}
                                            onChange={(e) => setFormData({ ...formData, addressLine2: e.target.value })}
                                            placeholder="Enter address line 2"
                                        />
                                    </div>
                                </div>

                                <div className="qr-form-row">
                                    <div className="qr-form-group">
                                        <label>Town *</label>
                                        <input
                                            type="text"
                                            value={formData.town}
                                            onChange={(e) => setFormData({ ...formData, town: e.target.value })}
                                            required
                                            placeholder="Enter town"
                                        />
                                    </div>
                                    <div className="qr-form-group">
                                        <label>Post Code *</label>
                                        <input
                                            type="text"
                                            value={formData.postCode}
                                            onChange={(e) => setFormData({ ...formData, postCode: e.target.value })}
                                            required
                                            placeholder="Enter post code"
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="qr-form-group">
                            <label>Additional Notes</label>
                            <textarea
                                value={formData.notes}
                                onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                                rows={4}
                                placeholder="Enter additional notes or requirements"
                            />
                        </div>

                        <div className="qr-form-group">
                            <label>Purpose & Destination for Hire</label>
                            <textarea
                                value={formData.purpose}
                                onChange={(e) => setFormData({ ...formData, purpose: e.target.value })}
                                rows={4}
                                placeholder="Enter purpose and destination"
                            />
                        </div>
                    </div>                    

                    <div className="qr-form-actions">
                        <button type="submit" className="qr-submit-button">
                            {isEditMode ? 'Update Order' : 'Order Vehicle'}
                        </button>
                    </div>
                    {orderFormNotes && orderFormNotes.length > 0 && (
                        <div className="qr-form-notes">
                            {orderFormNotes.map((note, index) => (
                                <div
                                    key={note.id}
                                    className="qr-note"
                                    dangerouslySetInnerHTML={{ __html: note.content }}
                                />
                            ))}
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default OrderVehicle;