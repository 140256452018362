import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useAuth } from '../../../auth/AuthProvider';
import './AccidentReports.css';

const AccidentReportStatus = {
    Draft: 0,
    Submitted: 1,
    InProgress: 2,
    PendingInformation: 3,
    Resolved: 4,
    Closed: 5
};

const AccidentReports = () => {
    const [reports, setReports] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterBy, setFilterBy] = useState('all');
    const [sortField, setSortField] = useState('createdDate');
    const [sortDirection, setSortDirection] = useState('desc');
    const navigate = useNavigate();
    const { isAdmin, isManager } = useAuth();
    const isManagerOrAdmin = isAdmin || isManager;

    useEffect(() => {
        const fetchReports = async () => {
            try {
                const response = await fetch(`${ApiRepos}AccidentReport`, {
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch accident reports');
                }

                const data = await response.json();
                setReports(data);
                setFilteredReports(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchReports();
    }, []);

    useEffect(() => {
        let filtered = [...reports];

        // Apply search filter
        if (searchTerm) {
            const searchLower = searchTerm.toLowerCase();
            filtered = filtered.filter(report =>
                report.driverName?.toLowerCase().includes(searchLower) ||
                report.vehicleRegistration?.toLowerCase().includes(searchLower) ||
                report.id?.toString().includes(searchLower) ||
                report.businessName?.toLowerCase().includes(searchLower)
            );
        }

        // Apply status/date filter
        switch (filterBy) {
            case 'draft':
                filtered = filtered.filter(report => report.status === AccidentReportStatus.Draft);
                break;
            case 'submitted':
                filtered = filtered.filter(report => report.status === AccidentReportStatus.Submitted);
                break;
            case 'withInjuries':
                filtered = filtered.filter(report => report.hasInjuredParties);
                break;
            case 'today':
                const today = new Date().toDateString();
                filtered = filtered.filter(report =>
                    new Date(report.createdDate).toDateString() === today
                );
                break;
            case 'thisWeek':
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
                filtered = filtered.filter(report =>
                    new Date(report.createdDate) > oneWeekAgo
                );
                break;
            default:
                break;
        }

        // Apply sorting
        filtered.sort((a, b) => {
            let comparison = 0;
            switch (sortField) {
                case 'id':
                    comparison = a.id - b.id;
                    break;
                case 'driverName':
                    comparison = (a.driverName || '').localeCompare(b.driverName || '');
                    break;
                case 'accidentDate':
                    comparison = new Date(a.accidentDate) - new Date(b.accidentDate);
                    break;
                case 'createdDate':
                    comparison = new Date(a.createdDate) - new Date(b.createdDate);
                    break;
                default:
                    comparison = 0;
            }
            return sortDirection === 'desc' ? -comparison : comparison;
        });

        setFilteredReports(filtered);
    }, [reports, searchTerm, filterBy, sortField, sortDirection]);

    const handleSort = (field) => {
        if (field === sortField) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const getStatusBadge = (status) => {
        const statusClasses = {
            [AccidentReportStatus.Draft]: 'status-badge status-draft',
            [AccidentReportStatus.Submitted]: 'status-badge status-submitted',
            [AccidentReportStatus.InProgress]: 'status-badge status-in-progress',
            [AccidentReportStatus.PendingInformation]: 'status-badge status-pending',
            [AccidentReportStatus.Resolved]: 'status-badge status-resolved',
            [AccidentReportStatus.Closed]: 'status-badge status-closed'
        };

        const statusNames = {
            [AccidentReportStatus.Draft]: 'Draft',
            [AccidentReportStatus.Submitted]: 'Submitted',
            [AccidentReportStatus.InProgress]: 'In Progress',
            [AccidentReportStatus.PendingInformation]: 'Pending Info',
            [AccidentReportStatus.Resolved]: 'Resolved',
            [AccidentReportStatus.Closed]: 'Closed'
        };

        return (
            <span className={statusClasses[status]}>
                {statusNames[status]}
            </span>
        );
    };

    if (loading) return (
        <div className="loading-container">
            <div className="loading-text">Loading...</div>
        </div>
    );

    if (error) return (
        <div className="error-container">
            <div className="error-message">
                Error: {error}
            </div>
        </div>
    );

    return (
        <div className="accidentreports-container">
            <div className="accidentreports-header">
                <h1>ACCIDENT REPORTS</h1>
                <button
                    onClick={() => navigate('/dashboard/accident-support')}
                    className="action-button add-new"
                >
                    NEW ACCIDENT REPORT
                </button>
            </div>

            <div className="filters-container">
                <input
                    type="text"
                    placeholder="Search by driver, vehicle, or reference..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />

                <select
                    value={filterBy}
                    onChange={(e) => setFilterBy(e.target.value)}
                    className="filter-select"
                >
                    <option value="all">All Reports</option>
                    <option value="draft">Draft</option>
                    <option value="submitted">Submitted</option>
                    <option value="withInjuries">With Injuries</option>
                    <option value="today">Today</option>
                    <option value="thisWeek">This Week</option>
                </select>
            </div>

            <div className="table-container">
                <table className="accidentreports-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('id')} className="sortable-header">
                                Ref {sortField === 'id' && (sortDirection === 'asc' ? '?' : '?')}
                            </th>
                            <th onClick={() => handleSort('driverName')} className="sortable-header">
                                Driver {sortField === 'driverName' && (sortDirection === 'asc' ? '?' : '?')}
                            </th>
                            <th>Vehicle</th>
                            <th onClick={() => handleSort('accidentDate')} className="sortable-header">
                                Accident Date {sortField === 'accidentDate' && (sortDirection === 'asc' ? '?' : '?')}
                            </th>
                            <th>Status</th>
                            <th>Injuries</th>
                            {isManagerOrAdmin && (
                                <th>Created By</th>
                            )}
                            {isAdmin && (
                                <th>Business</th>
                            )}
                            <th onClick={() => handleSort('createdDate')} className="sortable-header">
                                Created Date {sortField === 'createdDate' && (sortDirection === 'asc' ? '?' : '?')}
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredReports.map(report => (
                            <tr key={report.id}>
                                <td>{report.id}</td>
                                <td>{report.driverName}</td>
                                <td>{report.vehicleRegistration}</td>
                                <td>{formatDate(report.accidentDate)}</td>
                                <td>{getStatusBadge(report.status)}</td>
                                <td>
                                    {report.hasInjuredParties && (
                                        <span className="injury-indicator">Yes</span>
                                    )}
                                </td>
                                {isManagerOrAdmin && (
                                    <td>{report.requestedByUser}</td>
                                )}
                                {isAdmin && (
                                    <td>{report.businessName}</td>
                                )}
                                <td>{formatDate(report.createdDate)}</td>
                                <td>
                                    <button
                                        onClick={() => navigate(`/dashboard/accident-support/${report.id}`)}
                                        className="btn-secondary"
                                    >
                                        EDIT
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AccidentReports;