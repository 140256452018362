import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { ApiRepos } from '../contracts/ContractVars';
import { USER_LEVELS } from './constants';

const AuthContext = createContext(null);

const LoadingSpinner = () => (
    <div className="loading-container">
        <div className="loading-spinner"></div>
    </div>
);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const checkPermission = useCallback((requiredLevel) => {
        if (!user) return false;
        return user.userLevel >= requiredLevel;
    }, [user]);

    const checkUserSession = useCallback(async () => {
        try {
            const response = await fetch(`${ApiRepos}account/current-user`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                setUser(null);
                setIsAuthenticated(false);
                return null;
            }

            const userData = await response.json();
            setUser(userData);
            setIsAuthenticated(true);
            return userData;
        } catch (error) {
            console.error('Session check error:', error);
            setUser(null);
            setIsAuthenticated(false);
            return null;
        } finally {
            setLoading(false);
            setInitialized(true);
        }
    }, []);

    const logout = useCallback(async () => {
        try {
            await fetch(`${ApiRepos}account/logout`, {
                credentials: 'include'
            });
        } finally {
            setUser(null);
            setIsAuthenticated(false);
            setInitialized(true);
            setLoading(false);
        }
    }, []);

    // Initial auth check only
    useEffect(() => {
        checkUserSession();
    }, [checkUserSession]);

    const value = {
        user,
        setUser,
        loading,
        initialized,
        checkUserSession,
        logout,
        isAuthenticated,
        setIsAuthenticated,
        checkPermission,
        isAdmin: user?.userLevel === USER_LEVELS.ADMIN,
        isManager: user?.userLevel === USER_LEVELS.BUSINESS_MANAGER,
        isUser: user?.userLevel === USER_LEVELS.BUSINESS_USER
    };

    // Only show loading spinner during initial load
    if (!initialized && loading) {
        return <LoadingSpinner />;
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthProvider;