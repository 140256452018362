import React from 'react';
import { Shield } from 'lucide-react';

// Utility function to get user level label
const getUserLevelLabel = (userLevel) => {
    switch (userLevel) {
        case 1:
            return { label: 'Business User', color: 'text-blue-600' };
        case 2:
            return { label: 'Business Manager', color: 'text-green-600' };
        case 9:
            return { label: 'SMC Administrator', color: 'text-purple-600' };
        default:
            return { label: 'Unknown User Level', color: 'text-gray-600' };
    }
};

const UserLevelDisplay = ({ userLevel }) => {
    const { label, color } = getUserLevelLabel(userLevel);

    return (
        <div className="flex items-center gap-2 px-3 py-2 rounded-lg bg-gray-50">
            <Shield className={`w-5 h-5 ${color}`} />
            <span className={`font-medium ${color}`}>{label}</span>
        </div>
    );
};

export default UserLevelDisplay;