import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useAuth } from '../../../auth/AuthProvider';

const Login = ({ onRegisterClick, onForgotClick, onLoginAttempt }) => {
    const [formData, setFormData] = useState({
        emailAddress: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { setUser, setIsAuthenticated } = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess('');

        // Clear any existing messages in the parent component
        onLoginAttempt();

        try {
            const response = await fetch(`${ApiRepos}account/login`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.error || 'Login failed');

            setUser(data);
            setIsAuthenticated(true);
            await new Promise(resolve => setTimeout(resolve, 100));  // Small delay to let state update
            navigate('/dashboard/home', { replace: true });
        } catch (err) {
            setError(err.message || 'Invalid login credentials');
        } finally {
            setIsLoading(false);
        }
    };

    // Rest of the component remains the same
    return (
        <div className="form-section login-section">
            <h2>Login to Your Account</h2>
            <form onSubmit={handleLogin}>
                <div className="form-group">
                    <label>Email Address</label>
                    <input
                        type="email"
                        value={formData.emailAddress}
                        onChange={(e) => setFormData({ ...formData, emailAddress: e.target.value })}
                        required
                        placeholder="Enter your email address"
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        required
                        placeholder="Enter your password"
                    />
                </div>
                {error && <div className="error-message">{error}</div>}
                {success && <div className="success-message">{success}</div>}
                <button type="submit" className="submit-button explore-now" disabled={isLoading}>
                    {isLoading ? 'Logging in...' : 'Login'}
                </button>
                <div className="form-links">
                    <button
                        type="button"
                        onClick={onForgotClick}
                        className="link-button"
                    >
                        Forgot Password?
                    </button>
                    <button
                        type="button"
                        onClick={onRegisterClick}
                        className="link-button"
                    >
                        Register Account
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Login;