import React from 'react';
import { Link } from 'react-router-dom';

const DashboardHeader = ({ toggleSidebar, opened, userData }) => {
    return (
        <header className="dashboard-header">
            <div className="dashboard-header-content">
                <div className="header-left">
                    <button
                        className="menu-toggle"
                        onClick={toggleSidebar}
                        aria-label="Toggle menu"
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>

                    <Link to="/dashboard" className="logo">
                        <img
                            src="../assets/images/motorent.jpg"
                            alt="Motorent"
                            height="40"
                        />
                    </Link>
                </div>

                <div className="header-right">
                    <div className="contact-info">
                        <a href="tel:02392002002" className="phone-link2">
                            02392 002002
                        </a>
                    </div>

                    <div className="user-info">
                        {userData && (
                            <>
                                <span className="user-name">
                                    Welcome, {userData.firstName}
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default DashboardHeader;