import React, { useState, useEffect } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useAuth } from '../../../auth/AuthProvider';
import {
    LineChart, Line, BarChart, Bar, PieChart, Pie,
    XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    ResponsiveContainer, Cell
} from 'recharts';
import './Reporting.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ background: 'white', padding: '10px', border: '1px solid #ddd', borderRadius: '4px' }}>
                <p style={{ margin: '0 0 5px 0', fontWeight: 500 }}>{label}</p>
                {payload.map((entry, index) => (
                    <p key={index} style={{ color: entry.color, margin: '2px 0' }}>
                        {entry.name}: {entry.value}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const Reporting = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [businesses, setBusinesses] = useState([]);
    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        const now = new Date();
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        setStartDate(firstDay.toISOString().split('T')[0]);
        setEndDate(now.toISOString().split('T')[0]);
    }, []);

    useEffect(() => {
        const fetchBusinesses = async () => {
            try {
                const response = await fetch(`${ApiRepos}reporting/businesses`, {
                    credentials: 'include'
                });
                if (!response.ok) throw new Error('Failed to load businesses');
                const data = await response.json();
                setBusinesses(data);
            } catch (err) {
                setError('Failed to load business options');
                console.error('Error fetching businesses:', err);
            }
        };

        if (user.userLevel === 9) {
            fetchBusinesses();
        }
    });

    useEffect(() => {
        const loadStats = async () => {
            if (!startDate || !endDate) return;

            try {
                setLoading(true);
                const params = new URLSearchParams();
                params.append('startDate', startDate);
                params.append('endDate', endDate);
                if (selectedBusiness) params.append('businessId', selectedBusiness);

                const response = await fetch(`${ApiRepos}reporting/stats?${params}`, {
                    credentials: 'include'
                });

                if (!response.ok) throw new Error('Failed to load stats');
                const data = await response.json();
                setStats(data);
                setError(null);
            } catch (err) {
                setError('Failed to load statistics');
                console.error('Error loading stats:', err);
            } finally {
                setLoading(false);
            }
        };

        if (user.userLevel === 9) {
            loadStats();
        }
    }, [startDate, endDate, selectedBusiness, user]);

    if (user.userLevel !== 9) {
        return (
            <div className="reporting-container">
                <div className="text-center py-4">
                    Access denied. Admin only area.
                </div>
            </div>
        );
    }

    return (
        <div className="reporting-container">
            <div className="reporting-header">
                <h1>Reporting Dashboard</h1>
            </div>

            <div className="filter-section">
                <div className="filter-grid">
                    <div className="filter-item">
                        <label>Start Date</label>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="filter-input"
                        />
                    </div>
                    <div className="filter-item">
                        <label>End Date</label>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="filter-input"
                        />
                    </div>
                    <div className="filter-item">
                        <label>Business</label>
                        <select
                            value={selectedBusiness}
                            onChange={(e) => setSelectedBusiness(e.target.value)}
                            className="filter-select"
                        >
                            <option value="">All Businesses</option>
                            {businesses.map(business => (
                                <option key={business.id} value={business.id}>
                                    {business.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            {error && (
                <div className="error-message">{error}</div>
            )}

            {loading ? (
                <div className="loading-message">Loading...</div>
            ) : stats && (
                <>
                    <div className="stats-grid">
                        <div className="stat-card">
                            <h3>Total Quote Requests</h3>
                            <div className="stat-value">{stats.totalQuotes}</div>
                        </div>
                        <div className="stat-card">
                            <h3>Total Direct Orders</h3>
                            <div className="stat-value">{stats.totalOrders}</div>
                        </div>
                        <div className="stat-card">
                            <h3>Pending Quotes</h3>
                            <div className="stat-value">{stats.pendingQuotes}</div>
                        </div>
                    </div>

                    <div className="charts-grid">
                        <div className="chart-card">
                            <h3>Vehicle Types Distribution</h3>
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChart>
                                    <Pie
                                        data={stats.vehicleTypes}
                                        dataKey="total"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={100}
                                        label
                                    >
                                        {stats.vehicleTypes.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>

                        <div className="chart-card">
                            <h3>Monthly Trends</h3>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={stats.monthlyTrends}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend />
                                    <Line type="monotone" dataKey="quotes" stroke="#8884d8" name="Quotes" />
                                    <Line type="monotone" dataKey="orders" stroke="#82ca9d" name="Orders" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                    {stats.businessStats && (
                        <div className="chart-card">
                            <h3>Business Performance</h3>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart data={stats.businessStats}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="businessName" />
                                    <YAxis />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend />
                                    <Bar dataKey="quotes" fill="#8884d8" name="Quotes" />
                                    <Bar dataKey="orders" fill="#82ca9d" name="Orders" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Reporting;