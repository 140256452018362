import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useAuth } from '../../../auth/AuthProvider';
import { toast } from 'react-toastify';

const ViewOrder = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { isAdmin, isManager, user } = useAuth();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [licensePlate, setLicensePlate] = useState('');
    const [isDateValid, setIsDateValid] = useState(true);

    const fetchOrder = useCallback(async () => {
        try {
            const response = await fetch(`${ApiRepos}order/${id}`, {
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Failed to fetch order details');
            }

            const data = await response.json();
            setOrder(data);
            setLicensePlate(data.licensePlate || '');
            // Check if hire date is in the past
            const fromDateTime = new Date(data.hireFromDate);
            fromDateTime.setHours(data.hireFromHour);
            fromDateTime.setMinutes(data.hireFromMinute);
            setIsDateValid(fromDateTime > new Date());

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchOrder();
    }, [fetchOrder]);

    const handleProcessOrder = async () => {
        try {
            const response = await fetch(`${ApiRepos}order/${id}/process`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(licensePlate)
            });

            if (!response.ok) throw new Error('Failed to process order');

            await fetchOrder();
            toast.success('Order processed successfully');
        } catch (err) {
            toast.error(err.message);
        }
    };

    const handleCancelOrder = async () => {
        toast.warn(
            <div>
                <p className="mb-4">Are you sure you want to cancel this order?</p>
                <div className="flex justify-end gap-2">
                    <button
                        className="action-button cancel-button"
                        onClick={() => toast.dismiss('cancelOrderConfirm')}
                    >
                        No
                    </button>
                    <button
                        className="action-button edit-button"
                        onClick={async () => {
                            toast.dismiss('cancelOrderConfirm');
                            try {
                                const response = await fetch(`${ApiRepos}order/${id}/status`, {
                                    method: 'PUT',
                                    headers: { 'Content-Type': 'application/json' },
                                    credentials: 'include',
                                    body: JSON.stringify(9)
                                });

                                if (!response.ok) throw new Error('Failed to cancel order');
                                await fetchOrder();
                                toast.success(`Order ${order.id} cancelled successfully`);
                            } catch (err) {
                                toast.error(`Error: ${err.message}`);
                            }
                        }}
                    >
                        Yes, Cancel
                    </button>
                </div>
            </div>,
            {
                position: "top-center",
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                draggable: false,
                toastId: 'cancelOrderConfirm'
            }
        );
    };

    const handleRequestChange = () => {
        const toastId = 'changeRequest';
        let changeNotes = '';

        toast.info(
            <div>
                <textarea
                    className="w-full p-2 mb-2 border rounded"
                    placeholder="Enter change request details..."
                    rows="3"
                    onChange={(e) => changeNotes = e.target.value}
                />
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={async () => {
                        if (!changeNotes.trim()) {
                            toast.error('Please enter change details');
                            return;
                        }

                        toast.dismiss(toastId);
                        try {
                            const response = await fetch(`${ApiRepos}order/${id}/request-change`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                credentials: 'include',
                                body: JSON.stringify({ orderId: id, changeNotes })
                            });

                            if (!response.ok) throw new Error('Failed to submit change request');
                            toast.success('Change request submitted successfully');
                        } catch (err) {
                            toast.error(`Error: ${err.message}`);
                        }
                    }}
                >
                    Submit
                </button>
            </div>,
            {
                position: "top-center",
                autoClose: false,
                closeButton: true,
                closeOnClick: false,
                draggable: false,
                toastId
            }
        );
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!order) return <div>Order not found</div>;

    const formatDateTime = (date, hour, minute) => {
        const formattedDate = new Date(date).toLocaleDateString();
        return `${formattedDate} ${hour}:${minute}`;
    };

    const getStatusText = (status) => {
        switch (status) {
            case 0: return 'Quote';
            case 1: return 'Received';
            case 5: return 'Processed';
            case 9: return 'Cancelled';
            default: return 'Unknown';
        }
    };

    // Determine what actions are available based on user role and order status
    const showEditButton =
        isAdmin || // Admins can always edit
        (order.status === 1 && ( // Status must be 1 (Received) for non-admins
            order.userId === user?.id || // User owns the order
            (isManager && order.businessId === user?.businessId) // Manager from same business
        ));

    // Show change request for non-admins when they can't edit
    const showChangeRequest = !isAdmin && !showEditButton;

    // Show admin controls
    const showAdminControls = isAdmin && order.status !== 9; // Not for cancelled orders

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h2>Order Ref {order.id} - Current Status: {getStatusText(order.status)}</h2>
                <div>
                    {showAdminControls && (
                        <div className="mb-4">
                            {order.status === 1 && !isDateValid ? (
                                <div className="text-red-600 bg-red-50 p-4 rounded-lg">
                                    <p className="font-medium mb-2">This order cannot be processed as the hire start date is in the past.</p>
                                    <p>Please either cancel the order or edit to update the dates.</p>
                                    <button
                                        onClick={handleCancelOrder}
                                        className="action-button cancel-button"
                                    >
                                        Cancel Order
                                    </button>
                                </div>
                            ) : (
                                <div className="flex items-center gap-4">
                                    {(order.status === 1 || order.status === 5) && (
                                        <>
                                            <input
                                                type="text"
                                                value={licensePlate}
                                                onChange={(e) => setLicensePlate(e.target.value)}
                                                placeholder="Enter license plate"
                                                className="border rounded px-2 py-1"
                                            />
                                            <button
                                                onClick={handleProcessOrder}
                                                className="action-button edit-button"
                                            >
                                                {order.status === 1 ? 'Process Order' : 'Update License Plate'}
                                            </button>
                                        </>
                                    )}                                    
                                </div>
                            )}
                        </div>
                    )}
                    {showEditButton && (
                        <>
                        <button onClick={() => navigate(`/dashboard/order?edit=${order.id}`)} className="action-button edit-button">
                            Edit Order
                        </button>
                        <button
                            onClick={handleCancelOrder}
                            className="action-button cancel-button"
                        >
                            Cancel Order
                            </button>
                        </>
                    )}
                    {showChangeRequest && (
                        <button onClick={handleRequestChange} className="action-button edit-button">
                            Request Change
                        </button>
                    )}
                </div>
            </div>            
            <div className="bg-white shadow-md rounded-lg p-6">

                <div className="grid grid-cols-2 gap-6">
                    <div>
                        <h3>Driver Details</h3>
                        <p><span className="font-medium">Name:</span> {order.driverName}</p>
                        <p><span className="font-medium">Email:</span> {order.driverEmail}</p>
                        <p><span className="font-medium">Mobile:</span> {order.mobile}</p>
                    </div>
                    <div>
                        <h3>Hire Details</h3>
                        <p><span className="font-medium">Vehicle Type:</span> {order.vehicleTypeName || 'N/A'}</p>                        
                        <p><span className="font-medium">From:</span> {formatDateTime(order.hireFromDate, order.hireFromHour, order.hireFromMinute)}</p>
                        <p><span className="font-medium">To:</span> {formatDateTime(order.hireToDate, order.hireToHour, order.hireToMinute)}</p>                        
                        <p><span className="font-medium">Logistics:</span> {order.logistics}</p>
                    </div>

                    {order.logistics === 'Delivery' && (
                        <div>
                            <h3>Delivery Address</h3>
                            <p>{order.addressLine1}</p>
                            {order.addressLine2 && <p>{order.addressLine2}</p>}
                            <p>{order.town}</p>
                            <p>{order.postCode}</p>
                        </div>
                    )}

                    <div>
                        <h3>Other</h3>
                        <p><span className="font-medium">Purpose:</span> {order.purpose || 'N/A'}</p>
                        <p><span className="font-medium">Notes:</span> {order.notes || 'N/A'}</p>
                        <p><span className="font-medium">PO Number:</span> {order.poNumber || 'N/A'}</p>                        
                        <p><span className="font-medium">Created By:</span> {order.requestedByUser}</p>
                        <p><span className="font-medium">Created Date:</span> {new Date(order.createdDate).toLocaleString()}</p>
                        {order.updatedByUser && (
                            <p><span className="font-medium">Last Updated By:</span> {order.updatedByUser}</p>
                        )}
                    </div>
                </div>
            </div>            
        </div>
    );
};

export default ViewOrder;