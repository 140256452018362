import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import { TimeSelector } from './TimeSelector';
import { validateDateTime, getMinDateTime } from './dateTimeUtils';
import './OrderVehicle.css';

const initialDateTime = getMinDateTime();

const QuoteRequest = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const editQuoteId = searchParams.get('edit');
    const isEditMode = !!editQuoteId;

    const [formData, setFormData] = useState({
        isDriver: false,
        driverName: '',
        driverEmail: '',
        mobile: '',
        vehicleType: '',
        poNumber: '',
        logistics: '',
        addressLine1: '',
        addressLine2: '',
        town: '',
        postCode: '',
        notes: '',
        purpose: '',
        hireFromDate: initialDateTime.date,
        hireFromHour: initialDateTime.hour,
        hireFromMinute: initialDateTime.minute,
        hireToDate: initialDateTime.date,
        hireToHour: (parseInt(initialDateTime.hour) + 1).toString().padStart(2, '0'),
        hireToMinute: initialDateTime.minute
    });

    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [businessAddresses, setBusinessAddresses] = useState([]);
    const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);
            try {
                const userResponse = await fetch(`${ApiRepos}account/current-user`, { credentials: 'include' });
                if (!userResponse.ok) throw new Error('Failed to fetch user data');
                const userData = await userResponse.json();
                setCurrentUser(userData);

                const typesResponse = await fetch(`${ApiRepos}lookup/vehicletypes/active`, { credentials: 'include' });
                if (!typesResponse.ok) throw new Error('Failed to fetch vehicle types');
                const typesData = await typesResponse.json();
                setVehicleTypes(typesData);

                // If in edit mode, fetch quote data
                if (isEditMode && editQuoteId) {
                    const quoteResponse = await fetch(`${ApiRepos}order/${editQuoteId}`, { credentials: 'include' });
                    if (!quoteResponse.ok) throw new Error('Failed to fetch quote details');
                    const quoteData = await quoteResponse.json();

                    // Set the form data
                    setFormData({
                        isDriver: false,
                        driverName: quoteData.driverName,
                        driverEmail: quoteData.driverEmail,
                        mobile: quoteData.mobile,
                        vehicleType: quoteData.vehicleTypeId.toString(),
                        poNumber: quoteData.poNumber || '',
                        logistics: quoteData.logistics,
                        addressLine1: quoteData.addressLine1 || '',
                        addressLine2: quoteData.addressLine2 || '',
                        town: quoteData.town || '',
                        postCode: quoteData.postCode || '',
                        notes: quoteData.notes || '',
                        purpose: quoteData.purpose || '',
                        hireFromDate: quoteData.hireFromDate.split('T')[0],
                        hireFromHour: quoteData.hireFromHour,
                        hireFromMinute: quoteData.hireFromMinute,
                        hireToDate: quoteData.hireToDate.split('T')[0],
                        hireToHour: quoteData.hireToHour,
                        hireToMinute: quoteData.hireToMinute
                    });

                    // If it's a delivery, fetch and set addresses
                    if (quoteData.logistics === 'Delivery') {
                        setShowDeliveryAddress(true);
                        const addressResponse = await fetch(`${ApiRepos}business/${userData.businessId}/addresses`, {
                            credentials: 'include'
                        });
                        if (addressResponse.ok) {
                            const addressData = await addressResponse.json();
                            setBusinessAddresses(addressData);
                        }
                    }
                }
            } catch (err) {
                setError(err.message);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, [editQuoteId, isEditMode]);

    const validateUKMobile = (phone) => {
        const regex = /^(?:(?:\+44)|(?:0))7\d{9}$/;
        return regex.test(phone.replace(/\s+/g, ''));
    };

    const handleDriverCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setFormData({
            ...formData,
            isDriver: isChecked,
            driverName: isChecked && currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : '',
            driverEmail: isChecked && currentUser ? currentUser.emailAddress : '',
            mobile: isChecked && currentUser ? currentUser.mobileNumber : ''
        });
    };

    const handleFromDateChange = (newFromDate) => {
        setFormData(prev => {
            const fromDateTime = new Date(`${newFromDate}T${prev.hireFromHour}:${prev.hireFromMinute}`);
            const toDateTime = new Date(`${prev.hireToDate}T${prev.hireToHour}:${prev.hireToMinute}`);

            if (fromDateTime >= toDateTime) {
                const newToDateTime = new Date(fromDateTime);
                newToDateTime.setHours(fromDateTime.getHours() + 1);

                return {
                    ...prev,
                    hireFromDate: newFromDate,
                    hireToDate: newToDateTime.toISOString().split('T')[0],
                    hireToHour: newToDateTime.getHours().toString().padStart(2, '0'),
                    hireToMinute: newToDateTime.getMinutes().toString().padStart(2, '0')
                };
            }
            return { ...prev, hireFromDate: newFromDate };
        });
    };

    const handleFromTimeChange = (type, value) => {
        setFormData(prev => {
            const newFormData = { ...prev, [type]: value };
            const fromDateTime = new Date(`${prev.hireFromDate}T${type === 'hireFromHour' ? value : prev.hireFromHour}:${type === 'hireFromMinute' ? value : prev.hireFromMinute}`);
            const toDateTime = new Date(`${prev.hireToDate}T${prev.hireToHour}:${prev.hireToMinute}`);

            if (fromDateTime >= toDateTime) {
                const newToDateTime = new Date(fromDateTime);
                newToDateTime.setHours(fromDateTime.getHours() + 1);

                return {
                    ...newFormData,
                    hireToDate: newToDateTime.toISOString().split('T')[0],
                    hireToHour: newToDateTime.getHours().toString().padStart(2, '0'),
                    hireToMinute: newToDateTime.getMinutes().toString().padStart(2, '0')
                };
            }
            return newFormData;
        });
    };

    const handleLogisticsChange = async (value) => {
        setFormData({ ...formData, logistics: value });
        if (value === 'Delivery') {
            setShowDeliveryAddress(true);
            try {
                const response = await fetch(`${ApiRepos}business/${currentUser.businessId}/addresses`, {
                    credentials: 'include'
                });
                if (!response.ok) throw new Error('Failed to fetch addresses');
                const data = await response.json();
                setBusinessAddresses(data);
            } catch (error) {
                console.error('Error fetching business addresses:', error);
                setError('Failed to load delivery addresses');
            }
        } else {
            setShowDeliveryAddress(false);
        }
    };

    const handleAddressSelection = (addressId) => {
        const selected = businessAddresses.find(addr => addr.id === parseInt(addressId));
        if (selected) {
            setFormData({
                ...formData,
                addressLine1: selected.addressLine1,
                addressLine2: selected.addressLine2 || '',
                town: selected.town,
                postCode: selected.postCode
            });
        }
    };

    const validateDateTimes = () => {
        const result = validateDateTime(
            formData.hireFromDate,
            formData.hireFromHour,
            formData.hireFromMinute,
            formData.hireToDate,
            formData.hireToHour,
            formData.hireToMinute
        );

        if (!result.isValid) {
            setError(result.error);
        }

        return result.isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateUKMobile(formData.mobile)) {
            setError('Please enter a valid UK mobile number');
            return;
        }

        if (!validateDateTimes()) {
            return;
        }

        const submitData = {
            driverName: formData.driverName,
            driverEmail: formData.driverEmail,
            mobile: formData.mobile,
            vehicleTypeId: parseInt(formData.vehicleType),
            poNumber: formData.poNumber,
            logistics: formData.logistics,
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            town: formData.town,
            postCode: formData.postCode,
            notes: formData.notes,
            purpose: formData.purpose,
            hireFromDate: formData.hireFromDate,
            hireFromHour: formData.hireFromHour,
            hireFromMinute: formData.hireFromMinute,
            hireToDate: formData.hireToDate,
            hireToHour: formData.hireToHour,
            hireToMinute: formData.hireToMinute
        };

        try {
            const url = isEditMode
                ? `${ApiRepos}order/${editQuoteId}`
                : `${ApiRepos}order/quote`;

            const response = await fetch(url, {
                method: isEditMode ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(submitData)
            });

            if (!response.ok) throw new Error('Failed to submit quote request');

            const quoteData = await response.json();
            alert(isEditMode ? 'Quote updated successfully' : 'Quote request submitted successfully');
            navigate(`/dashboard/quotes/${quoteData.id}`);
        } catch (err) {
            setError('Failed to submit quote request: ' + err.message);
        }
    };

    if (loading) return <div className="qr-loading">Loading...</div>;

    return (
        <div className="qr-container">
            <div className="qr-form-wrapper">
                <h2 className="qr-title">{isEditMode ? `Update Quote Request Ref ${editQuoteId}` : 'Request Quote'}</h2>
                {error && (
                    <div className="qr-error-container">
                        <div className="qr-error-message">
                            <svg xmlns="http://www.w3.org/2000/svg" className="qr-error-icon" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                            {error}
                        </div>
                    </div>
                )}
                <form onSubmit={handleSubmit} className="qr-form">
                    <div className="qr-form-section">
                        <div className="qr-form-row">
                            <label className="qr-checkbox-label">
                                <input type="checkbox" checked={formData.isDriver} onChange={handleDriverCheckboxChange} className="qr-checkbox-input" />
                                Tick if you will be the driver
                            </label>
                        </div>
                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label>Driver Name *</label>
                                <input type="text" value={formData.driverName} onChange={(e) => setFormData({ ...formData, driverName: e.target.value })} required placeholder="Enter driver name" disabled={formData.isDriver} />
                            </div>
                            <div className="qr-form-group">
                                <label>Driver Email *</label>
                                <input type="email" value={formData.driverEmail} onChange={(e) => setFormData({ ...formData, driverEmail: e.target.value })} required placeholder="Enter driver email" disabled={formData.isDriver} />
                            </div>
                            <div className="qr-form-group">
                                <label>Mobile Number *</label>
                                <input type="tel" value={formData.mobile} onChange={(e) => setFormData({ ...formData, mobile: e.target.value })} required placeholder="+44 7XXX XXX XXX" disabled={formData.isDriver} />
                            </div>
                        </div>

                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label>Vehicle Type *</label>
                                <select value={formData.vehicleType} onChange={(e) => setFormData({ ...formData, vehicleType: e.target.value })} required>
                                    <option value="">Select vehicle type...</option>
                                    {vehicleTypes.map((type) => (
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="qr-form-group">
                                <label>PO Number</label>
                                <input type="text" value={formData.poNumber} onChange={(e) => setFormData({ ...formData, poNumber: e.target.value })} placeholder="PO number if available" />
                            </div>
                        </div>

                        <div className="qr-form-row">
                            <div className="qr-form-group">
                                <label>Hire From *</label>
                                <div className="date-time-group">
                                    <input
                                        type="date"
                                        value={formData.hireFromDate}
                                        onChange={(e) => handleFromDateChange(e.target.value)}
                                        min={initialDateTime.date}
                                        required
                                        className="qr-date-input"
                                    />
                                    <TimeSelector
                                        selectedHour={formData.hireFromHour}
                                        selectedMinute={formData.hireFromMinute}
                                        onHourChange={(value) => handleFromTimeChange('hireFromHour', value)}
                                        onMinuteChange={(value) => handleFromTimeChange('hireFromMinute', value)}
                                        date={formData.hireFromDate}
                                        minDate={initialDateTime.date}
                                        minHour={initialDateTime.hour}
                                        minMinute={initialDateTime.minute}
                                    />
                                </div>
                            </div>
                            <div className="qr-form-group">
                                <label>Hire To *</label>
                                <div className="date-time-group">
                                    <input
                                        type="date"
                                        value={formData.hireToDate}
                                        onChange={(e) => setFormData({ ...formData, hireToDate: e.target.value })}
                                        min={formData.hireFromDate} // Updated to prevent earlier dates
                                        required
                                        className="qr-date-input"
                                    />
                                    <TimeSelector
                                        selectedHour={formData.hireToHour}
                                        selectedMinute={formData.hireToMinute}
                                        onHourChange={(value) => setFormData({ ...formData, hireToHour: value })}
                                        onMinuteChange={(value) => setFormData({ ...formData, hireToMinute: value })}
                                        date={formData.hireToDate}
                                        minDate={formData.hireFromDate}
                                        minHour={formData.hireFromHour}
                                        minMinute={formData.hireFromMinute}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="qr-form-group">
                            <label>Logistics *</label>
                            <select value={formData.logistics} onChange={(e) => handleLogisticsChange(e.target.value)} required>
                                <option value="">Select logistics option...</option>
                                <option value="Collection">Collection</option>
                                <option value="Delivery">Delivery</option>
                            </select>
                        </div>

                        {/* Delivery address fields integrated in the same section */}
                        {showDeliveryAddress && (
                            <>
                                <div className="qr-form-row">
                                    <div className="qr-form-group">
                                        <label>Select Existing Address</label>
                                        <select
                                            onChange={(e) => handleAddressSelection(e.target.value)}
                                        >
                                            <option value="">Select an existing address or enter new</option>
                                            {businessAddresses.map(addr => (
                                                <option key={addr.id} value={addr.id}>
                                                    {addr.addressLine1}, {addr.town}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="qr-form-row">
                                    <div className="qr-form-group">
                                        <label>Address Line 1 *</label>
                                        <input
                                            type="text"
                                            value={formData.addressLine1}
                                            onChange={(e) => setFormData({ ...formData, addressLine1: e.target.value })}
                                            required
                                            placeholder="Enter address line 1"
                                        />
                                    </div>
                                    <div className="qr-form-group">
                                        <label>Address Line 2</label>
                                        <input
                                            type="text"
                                            value={formData.addressLine2}
                                            onChange={(e) => setFormData({ ...formData, addressLine2: e.target.value })}
                                            placeholder="Enter address line 2"
                                        />
                                    </div>
                                </div>

                                <div className="qr-form-row">
                                    <div className="qr-form-group">
                                        <label>Town *</label>
                                        <input
                                            type="text"
                                            value={formData.town}
                                            onChange={(e) => setFormData({ ...formData, town: e.target.value })}
                                            required
                                            placeholder="Enter town"
                                        />
                                    </div>
                                    <div className="qr-form-group">
                                        <label>Post Code *</label>
                                        <input
                                            type="text"
                                            value={formData.postCode}
                                            onChange={(e) => setFormData({ ...formData, postCode: e.target.value })}
                                            required
                                            placeholder="Enter post code"
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="qr-form-group">
                            <label>Additional Notes</label>
                            <textarea value={formData.notes} onChange={(e) => setFormData({ ...formData, notes: e.target.value })} placeholder="Enter additional notes or requirements"></textarea>
                        </div>
                        <div className="qr-form-group">
                            <label>Purpose & Destination for Hire</label>
                            <textarea
                                value={formData.purpose}
                                onChange={(e) => setFormData({ ...formData, purpose: e.target.value })}
                                rows={4}
                                placeholder="Enter purpose and destination"
                            />
                        </div>
                    </div>

                    <div className="qr-form-actions">
                        <button type="submit" className="action-button edit-button">
                            {isEditMode ? 'Update Quote' : 'Request Quote'}
                        </button>&nbsp;&nbsp;
                        <button
                            type="button"
                            onClick={() => navigate('/dashboard/quotes')}
                            className="action-button cancel-button"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default QuoteRequest;