// dateTimeUtils.js
export const validateDateTime = (fromDate, fromHour, fromMinute, toDate, toHour, toMinute) => {
    const now = new Date();
    const fromDateTime = new Date(`${fromDate}T${fromHour}:${fromMinute}:00`);
    const toDateTime = new Date(`${toDate}T${toHour}:${toMinute}:00`);

    // Reset seconds and milliseconds for more accurate comparison
    now.setSeconds(0);
    now.setMilliseconds(0);

    if (fromDateTime < now) {
        return {
            isValid: false,
            error: 'Hire from date and time must be in the future'
        };
    }

    if (toDateTime <= fromDateTime) {
        return {
            isValid: false,
            error: 'Hire to date and time must be after the hire from date and time'
        };
    }

    const hoursDifference = (toDateTime - fromDateTime) / (1000 * 60 * 60);
    if (hoursDifference < 1) {
        return {
            isValid: false,
            error: 'Minimum hire period is 1 hour'
        };
    }

    return {
        isValid: true,
        error: null
    };
};

export const getMinDateTime = () => {
    const now = new Date();
    // Round up to the next 5 minutes
    const minutes = Math.ceil(now.getMinutes() / 5) * 5;
    now.setMinutes(minutes);
    now.setSeconds(0);
    now.setMilliseconds(0);

    return {
        date: now.toISOString().split('T')[0],
        hour: now.getHours().toString().padStart(2, '0'),
        minute: now.getMinutes().toString().padStart(2, '0')
    };
};