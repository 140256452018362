import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useAuth } from '../../../auth/AuthProvider';
import { toast } from 'react-toastify';

const ViewQuote = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { isAdmin, isManager, user } = useAuth();
    const [quote, setQuote] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [quoteAmount, setQuoteAmount] = useState('');

    const OrderStatus = {
        Quote: 0,
        Received: 1,
        Processed: 5,
        Cancelled: 9,
        CancelledQuote: 10
    };

    useEffect(() => {
        const fetchQuote = async () => {
            try {
                const response = await fetch(`${ApiRepos}order/quote/${id}`, {
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch quote details');
                }

                const data = await response.json();
                setQuote(data);
                setQuoteAmount(data.quoteAmount || '');
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchQuote();
    }, [id]);

    const handleSetQuoteAmount = async () => {
        try {
            const response = await fetch(`${ApiRepos}order/quote/${id}/price`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(parseFloat(quoteAmount))
            });

            if (!response.ok) throw new Error('Failed to set quote amount');

            const updatedQuote = await response.json();
            setQuote(updatedQuote);
        } catch (err) {
            toast.error('Failed to set quote amount: ' + err.message);
        }
    };

    const handleConvertToOrder = async () => {
        try {
            const response = await fetch(`${ApiRepos}order/quote/${id}/accept`, {
                method: 'PUT',
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Failed to convert quote to order');

            navigate('/dashboard/orders');
        } catch (err) {
            toast.error('Failed to convert quote to order: ' + err.message);
        }
    };

    const handleRequestChange = () => {
        const toastId = 'changeRequest';
        let changeNotes = '';

        toast.info(
            <div>
                <textarea
                    className="w-full p-2 mb-2 border rounded"
                    placeholder="Enter change request details or reason for cancellation..."
                    rows="3"
                    onChange={(e) => changeNotes = e.target.value}
                />
                <button
                    className="action-button edit-button"
                    onClick={async () => {
                        if (!changeNotes.trim()) {
                            toast.error('Please enter change details');
                            return;
                        }

                        toast.dismiss(toastId);
                        try {
                            const response = await fetch(`${ApiRepos}order/${id}/request-change`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                credentials: 'include',
                                body: JSON.stringify({ orderId: id, changeNotes })
                            });

                            if (!response.ok) throw new Error('Failed to submit change request');
                            toast.success('Change request submitted successfully');
                        } catch (err) {
                            toast.error(`Error: ${err.message}`);
                        }
                    }}
                >
                    Submit
                </button>
            </div>,
            {
                position: "top-center",
                autoClose: false,
                closeButton: true,
                closeOnClick: false,
                draggable: false,
                toastId
            }
        );
    };

    const handleCancelQuote = async () => {
        toast.warn(
            <div>
                <p className="mb-4">Are you sure you want to cancel this quote?</p>
                <div className="flex justify-end gap-2">
                    <button
                        className="action-button cancel-button"
                        onClick={() => toast.dismiss('cancelQuoteConfirm')}
                    >
                        No
                    </button>
                    <button
                        className="action-button edit-button"
                        onClick={async () => {
                            toast.dismiss('cancelQuoteConfirm');
                            try {
                                const response = await fetch(`${ApiRepos}order/${id}/status`, {
                                    method: 'PUT',
                                    headers: { 'Content-Type': 'application/json' },
                                    credentials: 'include',
                                    body: JSON.stringify(OrderStatus.CancelledQuote)
                                });

                                if (!response.ok) {
                                    const errorData = await response.json();
                                    throw new Error(errorData.error || 'Failed to cancel quote');
                                }
                                navigate('/dashboard/quotes');
                                toast.success(`Quote ${id} cancelled successfully`);
                            } catch (err) {
                                toast.error(`Error: ${err.message}`);
                            }
                        }}
                    >
                        Yes, Cancel
                    </button>
                </div>
            </div>,
            {
                position: "top-center",
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                draggable: false,
                toastId: 'cancelQuoteConfirm'
            }
        );
    };

    const formatDateTime = (date, hour, minute) => {
        const formattedDate = new Date(date).toLocaleDateString();
        return `${formattedDate} ${hour}:${minute}`;
    };

    // Control visibility of actions based on quote status and permissions
    const canEdit = (isAdmin ||
        ((quote?.userId === user?.id || (isManager && quote?.businessId === user?.businessId)) &&
            !quote?.quoteAmount)) &&
        quote?.status !== OrderStatus.CancelledQuote;

    const canCancel = (isAdmin ||
        ((quote?.userId === user?.id || (isManager && quote?.businessId === user?.businessId)) &&
            !quote?.quoteAmount)) &&
        quote?.status !== OrderStatus.CancelledQuote;

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!quote) return <div>Quote not found</div>;

    return (
        <div className="p-6">
            {/* Header Section */}
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h2>Quote Ref {id}</h2>
                    {quote.status === OrderStatus.CancelledQuote && (
                        <span className="text-red-600 font-bold">CANCELLED</span>
                    )}
                </div>
                <div className="flex gap-4 items-center">
                    {isAdmin && quote.status !== OrderStatus.CancelledQuote && (
                        <div className="flex items-center gap-2">
                            <label>Quote Amount (&pound;):</label>&nbsp;
                            <input
                                type="number"
                                step="0.01"
                                value={quoteAmount}
                                onChange={(e) => setQuoteAmount(e.target.value)}
                                className="w-32 p-2 border rounded"
                            />&nbsp;
                            <button
                                onClick={handleSetQuoteAmount}
                                className="action-button edit-button"
                            >
                                Set Amount
                            </button>
                        </div>
                    )}
                    {!isAdmin && (
                        <div className="flex items-center gap-2">
                            {quote.quoteAmount && (
                                <span className="font-medium">Quote Amount: &pound;{quote.quoteAmount.toFixed(2)}</span>
                            )}
                        </div>
                    )}

                    {quote.status !== OrderStatus.CancelledQuote && (
                        <><br />
                            <button
                                onClick={handleConvertToOrder}
                                className="action-button edit-button"
                            >
                                Convert to Order
                            </button>

                            {canEdit && (
                                <button
                                    onClick={() => navigate(`/dashboard/quote?edit=${id}`)}
                                    className="action-button edit-button"
                                >
                                    Edit Quote
                                </button>
                            )}

                            {canCancel && (
                                <button
                                    onClick={handleCancelQuote}
                                    className="action-button cancel-button"
                                >
                                    Cancel Quote
                                </button>
                            )}

                            {quote.quoteAmount && !isAdmin && (
                                <button
                                    onClick={handleRequestChange}
                                    className="action-button edit-button"
                                >
                                    Request Change or Cancel
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* Main Content - rest remains the same */}
            <div className="bg-white shadow-md rounded-lg p-6">
                {/* Existing content sections remain unchanged */}
                <div className="grid grid-cols-2 gap-6">
                    {/* Driver Details */}
                    <div>
                        <h3 className="text-lg font-semibold mb-3">Driver Details</h3>
                        <p><span className="font-medium">Name:</span> {quote.driverName}</p>
                        <p><span className="font-medium">Email:</span> {quote.driverEmail}</p>
                        <p><span className="font-medium">Mobile:</span> {quote.mobile}</p>
                    </div>

                    {/* Hire Details */}
                    <div>
                        <h3 className="text-lg font-semibold mb-3">Hire Details</h3>
                        <p><span className="font-medium">Vehicle Type:</span> {quote.vehicleTypeName}</p>
                        <p>
                            <span className="font-medium">From:</span> {' '}
                            {formatDateTime(quote.hireFromDate, quote.hireFromHour, quote.hireFromMinute)}
                        </p>
                        <p>
                            <span className="font-medium">To:</span> {' '}
                            {formatDateTime(quote.hireToDate, quote.hireToHour, quote.hireToMinute)}
                        </p>
                        <p><span className="font-medium">Logistics:</span> {quote.logistics}</p>
                    </div>

                    {/* Delivery Address (if applicable) */}
                    {quote.logistics === 'Delivery' && (
                        <div>
                            <h3 className="text-lg font-semibold mb-3">Delivery Address</h3>
                            <p>{quote.addressLine1}</p>
                            {quote.addressLine2 && <p>{quote.addressLine2}</p>}
                            <p>{quote.town}</p>
                            <p>{quote.postCode}</p>
                        </div>
                    )}

                    {/* Additional Details */}
                    <div>
                        <h3 className="text-lg font-semibold mb-3">Additional Details</h3>
                        <p><span className="font-medium">Purpose:</span> {quote.purpose || 'N/A'}</p>
                        <p><span className="font-medium">Notes:</span> {quote.notes || 'N/A'}</p>
                        <p><span className="font-medium">PO Number:</span> {quote.poNumber || 'N/A'}</p>
                        <p><span className="font-medium">Created By:</span> {quote.requestedByUser}</p>
                        <p><span className="font-medium">Created Date:</span> {new Date(quote.createdDate).toLocaleString()}</p>
                        {quote.updatedByUser && (
                            <p>
                                <span className="font-medium">Last Updated By:</span> {quote.updatedByUser}
                                on {new Date(quote.updatedDate).toLocaleString()}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewQuote;