import React, { useState, useEffect } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';
import './UserProfile.css';

const UserProfile = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        mobileNumber: '',
        profilePictureUrl: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${ApiRepos}account/current-user`, {
                    credentials: 'include'
                });
                if (!response.ok) throw new Error('Failed to fetch user data');
                const userData = await response.json();

                setFormData(prevData => ({
                    ...prevData,
                    firstName: userData.firstName || '',
                    lastName: userData.lastName || '',
                    emailAddress: userData.emailAddress || '',
                    mobileNumber: userData.mobileNumber || '',
                    profilePictureUrl: userData.profilePictureUrl || '',
                }));
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleFileSelect = (e) => {
        if (e.target.files && e.target.files[0]) {
            handleUploadProfilePicture(e.target.files[0]);
        }
    };

    const handleUploadProfilePicture = async (file) => {
        setUploadLoading(true);
        setError(null);
        setSuccessMessage('');

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`${ApiRepos}account/upload-profile-picture`, {
                method: 'POST',
                credentials: 'include',
                body: formData
            });

            if (!response.ok) throw new Error('Failed to upload profile picture');

            const data = await response.json();
            setFormData(prev => ({
                ...prev,
                profilePictureUrl: data.url
            }));
            setSuccessMessage('Profile picture updated successfully');
        } catch (err) {
            setError('Failed to upload profile picture');
        } finally {
            setUploadLoading(false);
        }
    };

    const handleRemoveProfilePicture = async () => {
        if (!window.confirm('Are you sure you want to remove your profile picture?')) return;

        setUploadLoading(true);
        setError(null);
        setSuccessMessage('');

        try {
            const response = await fetch(`${ApiRepos}account/remove-profile-picture`, {
                method: 'DELETE',
                credentials: 'include',
            });

            if (!response.ok) throw new Error('Failed to remove profile picture');

            setFormData(prev => ({
                ...prev,
                profilePictureUrl: ''
            }));
            setSuccessMessage('Profile picture removed successfully');
        } catch (err) {
            setError('Failed to remove profile picture');
        } finally {
            setUploadLoading(false);
        }
    };

    const validateUKMobile = (phone) => {
        const regex = /^(?:(?:\+44)|(?:0))7\d{9}$/;
        return regex.test(phone.replace(/\s+/g, ''));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccessMessage('');

        if (!validateUKMobile(formData.mobileNumber)) {
            setError('Please enter a valid UK mobile number');
            return;
        }

        if (showPasswordFields) {
            if (formData.newPassword !== formData.confirmPassword) {
                setError('New passwords do not match');
                return;
            }
        }

        const profileData = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            emailAddress: formData.emailAddress,
            mobileNumber: formData.mobileNumber,
            currentPassword: showPasswordFields ? formData.currentPassword : null,
            newPassword: showPasswordFields ? formData.newPassword : null,
        };

        try {
            const response = await fetch(`${ApiRepos}account/update-profile`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(profileData)
            });

            if (!response.ok) throw new Error('Failed to update profile');
            setSuccessMessage('Profile updated successfully');
            setShowPasswordFields(false);
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) return <div className="profile-loading">Loading...</div>;

    return (
        <div className="profile-container">
            <div className="profile-form-wrapper">
                <h2 className="profile-title">My Profile</h2>

                {/* Display error and success messages */}
                {error && (
                    <div className="error-message">{error}</div>
                )}
                {successMessage && (
                    <div className="success-message">{successMessage}</div>
                )}

                <form onSubmit={handleSubmit} className="profile-form">
                    <div className="form-group">
                        <div className="profile-upload1">
                            {formData.profilePictureUrl ? (
                                <div>
                                    <img
                                        src={formData.profilePictureUrl}
                                        alt="Profile"
                                        style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'cover' }}
                                    />
                                    <button
                                        type="button"
                                        onClick={handleRemoveProfilePicture}
                                        disabled={uploadLoading}
                                        className="remove-profile-image-button"
                                    >
                                        Remove Picture
                                    </button>
                                </div>
                            ) : (
                                <div>No profile picture</div>
                            )}

                            <input
                                type="file"
                                onChange={handleFileSelect}
                                accept="image/*"
                                disabled={uploadLoading}
                            />

                            {uploadLoading && <div>Uploading...</div>}
                        </div>
                    </div>

                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group">
                                <label>First Name *</label>
                                <input
                                    type="text"
                                    value={formData.firstName}
                                    onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                                    required
                                    maxLength={100}
                                />
                            </div>
                            <div className="form-group">
                                <label>Last Name *</label>
                                <input
                                    type="text"
                                    value={formData.lastName}
                                    onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                                    required
                                    maxLength={100}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <label>Email Address *</label>
                                <input
                                    type="email"
                                    value={formData.emailAddress}
                                    onChange={(e) => setFormData({ ...formData, emailAddress: e.target.value })}
                                    required
                                    maxLength={255}
                                />
                            </div>
                            <div className="form-group">
                                <label>Mobile Number *</label>
                                <input
                                    type="tel"
                                    value={formData.mobileNumber}
                                    onChange={(e) => setFormData({ ...formData, mobileNumber: e.target.value })}
                                    required
                                    maxLength={20}
                                    placeholder="+44 7XXX XXX XXX"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-section">
                        <div className="form-group">
                            <button
                                type="button"
                                className="link-button"
                                onClick={() => setShowPasswordFields(!showPasswordFields)}
                            >
                                {showPasswordFields ? 'Cancel Password Change' : 'Change Password'}
                            </button>
                        </div>

                        {showPasswordFields && (
                            <div className="password-fields">
                                <div className="form-group">
                                    <label>Current Password *</label>
                                    <input
                                        type="password"
                                        value={formData.currentPassword}
                                        onChange={(e) => setFormData({ ...formData, currentPassword: e.target.value })}
                                        required={showPasswordFields}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>New Password *</label>
                                    <input
                                        type="password"
                                        value={formData.newPassword}
                                        onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                                        required={showPasswordFields}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Confirm New Password *</label>
                                    <input
                                        type="password"
                                        value={formData.confirmPassword}
                                        onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                                        required={showPasswordFields}
                                    />
                                </div>
                            </div>
                        )}
                        <button type="submit" className="action-button edit-button">
                            Update Profile
                        </button>
                    </div>                            
                </form>
            </div>
        </div>
    );
};

export default UserProfile;
