import React, { useState, useEffect } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';
import './FAQs.css';

const FAQs = () => {
    const [faqs, setFaqs] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [formData, setFormData] = useState({
        question: '',
        answer: '',
        displayOrder: 0,
        isActive: true
    });
    const [selectedFaq, setSelectedFaq] = useState(null);

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await fetch(`${ApiRepos}faq`, {
                    credentials: 'include'
                });
                if (!response.ok) throw new Error('Failed to fetch FAQs');
                const data = await response.json();
                setFaqs(data);

                // Get current user to check admin status
                const userResponse = await fetch(`${ApiRepos}account/current-user`, {
                    credentials: 'include'
                });
                if (!userResponse.ok) throw new Error('Failed to fetch user data');
                const userData = await userResponse.json();
                setIsAdmin(userData.userLevel === 9);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchFaqs();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccessMessage('');

        try {
            const url = selectedFaq
                ? `${ApiRepos}faq/${selectedFaq.id}`
                : `${ApiRepos}faq`;

            const response = await fetch(url, {
                method: selectedFaq ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(formData)
            });

            if (!response.ok) throw new Error(`Failed to ${selectedFaq ? 'update' : 'create'} FAQ`);

            setSuccessMessage(`FAQ ${selectedFaq ? 'updated' : 'created'} successfully`);
            setShowForm(false);
            setSelectedFaq(null);

            // Refresh FAQs list
            const updatedResponse = await fetch(`${ApiRepos}faq`, {
                credentials: 'include'
            });
            if (!updatedResponse.ok) throw new Error('Failed to refresh FAQs');
            const updatedData = await updatedResponse.json();
            setFaqs(updatedData);

            // Reset form
            setFormData({
                question: '',
                answer: '',
                displayOrder: 0,
                isActive: true
            });
        } catch (err) {
            setError(err.message);
        }
    };

    const handleEdit = (faq) => {
        setFormData({
            question: faq.question,
            answer: faq.answer,
            displayOrder: faq.displayOrder,
            isActive: faq.isActive
        });
        setSelectedFaq(faq);
        setShowForm(true);
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this FAQ?')) {
            try {
                const response = await fetch(`${ApiRepos}faq/${id}`, {
                    method: 'DELETE',
                    credentials: 'include'
                });

                if (!response.ok) throw new Error('Failed to delete FAQ');

                setSuccessMessage('FAQ deleted successfully');
                setFaqs(faqs.filter(faq => faq.id !== id));
            } catch (err) {
                setError(err.message);
            }
        }
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className="faqs-container">
            <div className="faqs-container-header">
                <h1>Frequently Asked Questions</h1>
                {isAdmin && !showForm && (
                    <button
                        className="action-button add-new"
                        onClick={() => setShowForm(true)}
                    >
                        Add New FAQ
                    </button>
                )}
            </div>

            {successMessage && (
                <div className="success-message">{successMessage}</div>
            )}

            {showForm && (
                <div className="form-wrapper">
                    <form onSubmit={handleSubmit} className="faq-form">
                        <div className="form-section">
                            <div className="form-group">
                                <label>Question *</label>
                                <input
                                    type="text"
                                    value={formData.question}
                                    onChange={(e) => setFormData({ ...formData, question: e.target.value })}
                                    required
                                    maxLength={255}
                                />
                            </div>
                            <div className="form-group">
                                <label>Answer *</label>
                                <textarea
                                    value={formData.answer}
                                    onChange={(e) => setFormData({ ...formData, answer: e.target.value })}
                                    required
                                    rows={4}
                                />
                            </div>
                            <div className="form-group">
                                <label>Display Order</label>
                                <input
                                    type="number"
                                    value={formData.displayOrder}
                                    onChange={(e) => setFormData({ ...formData, displayOrder: parseInt(e.target.value) })}
                                    min="0"
                                />
                            </div>
                        </div>

                        <div className="form-actions">
                            <button type="button" className="link-button" onClick={() => {
                                setShowForm(false);
                                setSelectedFaq(null);
                                setFormData({
                                    question: '',
                                    answer: '',
                                    displayOrder: 0,
                                    isActive: true
                                });
                            }}>
                                Cancel
                            </button>
                            <button type="submit" className="submit-button">
                                {selectedFaq ? 'Update FAQ' : 'Create FAQ'}
                            </button>
                        </div>
                    </form>
                </div>
            )}

            <div className="faqs-list">
                {faqs.map((faq) => (
                    <div key={faq.id} className="faq-item">
                        <div className="faq-header">
                            <h3>{faq.question}</h3>
                            {isAdmin && (
                                <div className="faq-actions">
                                    <button
                                        className="link-button"
                                        onClick={() => handleEdit(faq)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="link-button"
                                        onClick={() => handleDelete(faq.id)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            )}
                        </div>
                        <p className="faq-answer">{faq.answer}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQs;