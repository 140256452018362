import React from 'react';
import UserLevelDisplay from './UserLevelDisplay';
import { useAuth } from '../../../auth/AuthProvider';

const DashboardHome = () => {
    // Use the user context instead of props
    const { user } = useAuth();

    return (
        <div className="dashboard-content">
            <div className="content-wrapper">
                <div className="text-center">
                    <h1>HELLO, {user.firstName?.toUpperCase()}!</h1>
                    <p>Welcome to your dashboard</p>
                    <UserLevelDisplay userLevel={user.userLevel} />
                </div>
            </div>
        </div>
    );
};

export default DashboardHome;