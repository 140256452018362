import React from 'react';

export const TimeSelector = ({
    selectedHour,
    selectedMinute,
    onHourChange,
    onMinuteChange,
    minHour = '00',
    minMinute = '00',
    date,
    minDate
}) => {
    const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
    const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

    const isHourDisabled = (hour) => {
        if (date === minDate) {
            return parseInt(hour) < parseInt(minHour);
        }
        return false;
    };

    const isMinuteDisabled = (minute) => {
        if (date === minDate && selectedHour === minHour) {
            return parseInt(minute) < parseInt(minMinute);
        }
        return false;
    };

    return (
        <div className="time-selector">
            <select
                value={selectedHour}
                onChange={(e) => onHourChange(e.target.value)}
                className="time-select"
            >
                {hours.map(hour => (
                    <option
                        key={hour}
                        value={hour}
                        disabled={isHourDisabled(hour)}
                    >
                        {hour}
                    </option>
                ))}
            </select>
            <span className="time-separator">:</span>
            <select
                value={selectedMinute}
                onChange={(e) => onMinuteChange(e.target.value)}
                className="time-select"
            >
                {minutes.map(minute => (
                    <option
                        key={minute}
                        value={minute}
                        disabled={isMinuteDisabled(minute)}
                    >
                        {minute}
                    </option>
                ))}
            </select>
        </div>
    );
};