import React, { useState } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';
import './UserCard.css';

const UserCard = ({
    user,
    onUserUpdated,
    onStatusToggled,
    onCancel,
    isNew,
    currentUserLevel,
    businesses,
    selectedNewUserBusiness,
    setSelectedNewUserBusiness
}) => {
    const [isEditing, setIsEditing] = useState(isNew);
    const [editedUser, setEditedUser] = useState(user);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (isNew && currentUserLevel === 9 && !selectedNewUserBusiness) {
            setError('Please select a business');
            setLoading(false);
            return;
        }

        try {
            const url = isNew
                ? `${ApiRepos}users`
                : `${ApiRepos}users/${user.id}`;

            const method = isNew ? 'POST' : 'PUT';

            // If admin is creating user, use selected business
            const userToSubmit = {
                ...editedUser,
                businessId: currentUserLevel === 9 ? selectedNewUserBusiness : editedUser.businessId
            };

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(userToSubmit)
            });

            if (!response.ok) throw new Error('Failed to save user');

            const updatedUser = await response.json();
            onUserUpdated(updatedUser);
            if (!isNew) setIsEditing(false);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleToggleStatus = async () => {
        if (!window.confirm(`Are you sure you want to ${user.isActive ? 'deactivate' : 'activate'} this user?`)) return;

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${ApiRepos}users/${user.id}/toggle-status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ isActive: !user.isActive })
            });

            if (!response.ok) throw new Error('Failed to update user status');

            onStatusToggled(user.id, !user.isActive);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFileSelect = (e) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) return;

        setLoading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await fetch(`${ApiRepos}users/${user.id}/upload-profile-picture`, {
                method: 'POST',
                credentials: 'include',
                body: formData
            });

            if (!response.ok) throw new Error('Failed to upload image');

            const data = await response.json();
            setEditedUser(prev => ({
                ...prev,
                profilePictureUrl: data.url
            }));
            onUserUpdated({ ...user, profilePictureUrl: data.url });
            setSelectedFile(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveProfilePicture = async () => {
        if (!window.confirm('Are you sure you want to remove this user\'s profile picture?')) return;

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${ApiRepos}users/${user.id}/remove-profile-picture`, {
                method: 'DELETE',
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Failed to remove profile picture');

            setEditedUser(prev => ({
                ...prev,
                profilePictureUrl: ''
            }));
            onUserUpdated({ ...user, profilePictureUrl: '' });
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div className="user-card loading">Loading...</div>;

    if (isEditing) {
        return (
            <div className="user-card editing">
                <form onSubmit={handleSubmit}>
                    {/* Business Selection for Admin (level 9) when creating new user */}
                    {isNew && currentUserLevel === 9 && (
                        <div className="form-group">
                            <label>Select Business *</label>
                            <select
                                value={selectedNewUserBusiness}
                                onChange={(e) => setSelectedNewUserBusiness(e.target.value)}
                                required
                                className="filter-select"
                            >
                                <option value="">Select Business</option>
                                {businesses.map(business => (
                                    <option key={business.id} value={business.id}>
                                        {business.businessName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {/* Profile picture upload for existing users */}
                    {!isNew && (
                        <div className="form-group">
                            <label>Profile Picture</label>
                            <div className="profile-upload">
                                {editedUser.profilePictureUrl && (
                                    <div className="current-image">
                                        <img
                                            src={editedUser.profilePictureUrl}
                                            alt="Profile"
                                            className="preview-image"
                                        />
                                        <button
                                            type="button"
                                            onClick={handleRemoveProfilePicture}
                                            className="remove-image-button"
                                            title="Remove picture"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                )}
                                <input
                                    type="file"
                                    onChange={handleFileSelect}
                                    accept="image/*"
                                    className="file-input"
                                />
                                {selectedFile && (
                                    <button
                                        type="button"
                                        onClick={handleUpload}
                                        className="upload-button"
                                    >
                                        Upload Image
                                    </button>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="form-group">
                        <label>First Name *</label>
                        <input
                            type="text"
                            value={editedUser.firstName}
                            onChange={(e) => setEditedUser({ ...editedUser, firstName: e.target.value })}
                            required
                            maxLength={100}
                        />
                    </div>

                    <div className="form-group">
                        <label>Last Name *</label>
                        <input
                            type="text"
                            value={editedUser.lastName}
                            onChange={(e) => setEditedUser({ ...editedUser, lastName: e.target.value })}
                            required
                            maxLength={100}
                        />
                    </div>

                    <div className="form-group">
                        <label>Email Address *</label>
                        <input
                            type="email"
                            value={editedUser.emailAddress}
                            onChange={(e) => setEditedUser({ ...editedUser, emailAddress: e.target.value })}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Mobile Number *</label>
                        <input
                            type="tel"
                            value={editedUser.mobileNumber}
                            onChange={(e) => setEditedUser({ ...editedUser, mobileNumber: e.target.value })}
                            required
                            maxLength={20}
                        />
                    </div>

                    {currentUserLevel >= 2 && (
                        <div className="form-group">
                            <label>User Level *</label>
                            <select
                                value={editedUser.userLevel}
                                onChange={(e) => setEditedUser({
                                    ...editedUser,
                                    userLevel: parseInt(e.target.value)
                                })}
                                required
                            >
                                <option value={1}>Business User</option>
                                <option value={2}>Business Manager</option>
                                {currentUserLevel === 9 && (
                                    <option value={9}>Administrator</option>
                                )}
                            </select>
                        </div>
                    )}

                    {isNew && (
                        <div className="form-group">
                            <label>Password</label>
                            <input
                                type="text"
                                value="Auto created"
                                disabled
                                className="disabled-input"
                            />
                            <small className="help-text">A random password will be generated and sent to the user's email.</small>
                        </div>
                    )}

                    {error && <div className="error-message">{error}</div>}

                    <div className="card-actions">
                        <button type="submit" className="submit-button" disabled={loading}>
                            {isNew ? 'Add User' : 'Save Changes'}
                        </button>
                        <button
                            type="button"
                            className="cancel-button"
                            onClick={isNew ? onCancel : () => setIsEditing(false)}
                            disabled={loading}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        );
    }

    // View mode
    return (
        <div className="user-card">
            <div className="user-profile">
                <div className={`user-status ${user.isActive ? 'active' : 'inactive'}`}>
                    {user.isActive ? 'Active' : 'Inactive'}
                </div>
                <div className="user-avatar">
                    {user.profilePictureUrl ? (
                        <img src={user.profilePictureUrl} alt={`${user.firstName} ${user.lastName}`} />
                    ) : (
                        <span>{user.firstName[0]}{user.lastName[0]}</span>
                    )}
                </div>
                <div>
                    <h3 className="user-name">
                        {user.firstName}<br />
                        {user.lastName}
                    </h3>
                    {currentUserLevel === 9 && (
                        <div className="user-business">{user.businessName}</div>
                    )}
                </div>                
            </div>
            

            <div className="user-info">
                <p className="user-email">{user.emailAddress}</p>
                <p className="user-mobile">{user.mobileNumber}</p>                
            </div>
            <p className="user-level">
                {user.userLevel === 9 ? 'Administrator' : user.userLevel === 2 ? 'Business Manager' : 'Business User'}
            </p>            

            <div className="card-actions">
                <button
                    onClick={() => setIsEditing(true)}
                    className="edit-button"
                    disabled={loading}
                >
                    Edit
                </button>
                <button
                    onClick={handleToggleStatus}
                    className={`deactivate-button`}
                    disabled={loading}
                >
                    {user.isActive ? 'Deactivate' : 'Activate'}
                </button>
            </div>
        </div>
    );
};

export default UserCard;