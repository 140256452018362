import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../auth/AuthProvider';
import { ApiRepos } from '../../../contracts/ContractVars';
import './AccidentSupport.css';

const AccidentReportStatus = {
    Draft: 0,
    Submitted: 1,
    InProgress: 2,
    PendingInformation: 3,
    Resolved: 4,
    Closed: 5
};

const getTodayString = () => {
    return new Date().toISOString().split('T')[0];
};

const initialFormData = {
    // Basic Fields
    status: AccidentReportStatus.Draft,
    isDriver: false,
    driverName: '',
    driverTelephone: '',
    accidentDate: getTodayString(),
    accidentTime: '',
    accidentLocation: '',
    vehicleRegistration: '',
    damageDescription: '',

    // Optional Fields
    driverAddress: '',
    driverEmail: '',
    driverDateOfBirth: null,
    driverOccupation: '',
    drivingLicenceNumber: '',
    licenceHeldDuration: '',
    hireVehiclePurpose: '',
    natureOfGoods: '',
    vehicleMake: '',
    vehicleModel: '',
    roadConditions: '',
    weatherConditions: '',
    estimatedSpeed: '',
    lightsUsed: '',
    roadPosition: '',
    wasHirerResponsible: null,
    accidentDescription: '',

    // Flags
    hasInjuredParties: false,
    hasThirdParties: false,
    hasPoliceInvolved: false,

    // Arrays and nested objects
    thirdParties: [{
        name: '',
        address: '',
        telephoneNumber: '',
        vehicleMakeModel: '',
        vehicleRegistration: '',
        insurersDetails: '',
        speed: '',
        vehicleDamage: '',
        numberOfOccupants: null
    }],
    injuredPersons: [{
        name: '',
        address: '',
        injuryDetails: '',
        ambulanceAttended: false,
        ambulanceDetails: ''
    }],
    passengers: [{
        name: '',
        address: ''
    }],
    witnesses: [{
        name: '',
        address: ''
    }],
    policeDetails: {
        officerName: '',
        officerNumber: '',
        stationAddress: ''
    }
};

const AccidentSupport = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialFormData);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [reportId, setReportId] = useState(id || null);
    const [photos, setPhotos] = useState([]);
    const [photoUploading, setPhotoUploading] = useState(false);
    const [canBeSubmitted, setCanBeSubmitted] = useState(false);

    const checkSubmissionEligibility = useCallback((data) => {
        const isEligible = Boolean(
            data.accidentDescription &&
            (photos?.length > 0) &&
            (!data.hasThirdParties || data.thirdParties?.every(tp => tp.insurersDetails)) &&
            (!data.hasInjuredParties || data.injuredPersons?.every(ip => ip.injuryDetails)) &&
            data.status === AccidentReportStatus.Draft
        );
        setCanBeSubmitted(isEligible);
    }, [photos]);

    useEffect(() => {
        checkSubmissionEligibility(formData);
    }, [formData, checkSubmissionEligibility]);

    useEffect(() => {
        const loadExistingReport = async () => {
            if (!reportId) return;

            setLoading(true);
            try {
                const response = await fetch(`${ApiRepos}AccidentReport/${reportId}`, {
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Failed to load accident report');
                }

                const data = await response.json();
                setFormData({
                    ...initialFormData,
                    ...data,
                    accidentDate: data.accidentDate.split('T')[0],
                    driverDateOfBirth: data.driverDateOfBirth ? data.driverDateOfBirth.split('T')[0] : null,
                    thirdParties: data.thirdParties || [],
                    injuredPersons: data.injuredPersons || [],
                    passengers: data.passengers || [],
                    witnesses: data.witnesses || [],
                    policeDetails: data.policeDetails || {
                        officerName: '',
                        officerNumber: '',
                        stationAddress: ''
                    },
                    wasHirerResponsible: data.wasHirerResponsible || null
                });

                if (data.photos) {
                    setPhotos(data.photos);
                }
            } catch (err) {
                setError('Error loading report: ' + err.message);
                console.error('Error loading accident report:', err);
            } finally {
                setLoading(false);
            }
        };

        loadExistingReport();
    }, [reportId]);

    const handleDriverCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setFormData({
            ...formData,
            isDriver: isChecked,
            driverName: isChecked && user ? `${user.firstName} ${user.lastName}` : '',
            driverEmail: isChecked && user ? user.emailAddress : '',
            driverTelephone: isChecked && user ? user.mobileNumber : ''
        });
    };

    const handleChange = (e, section = null, index = null) => {
        const { name, value, type, checked } = e.target;

        if (section) {
            if (Array.isArray(formData[section])) {
                const newArray = [...formData[section]];
                newArray[index] = {
                    ...newArray[index],
                    [name]: type === 'checkbox' ? checked : value
                };
                setFormData(prev => ({
                    ...prev,
                    [section]: newArray
                }));
            } else {
                setFormData(prev => ({
                    ...prev,
                    [section]: {
                        ...prev[section],
                        [name]: type === 'checkbox' ? checked : value
                    }
                }));
            }
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: type === 'checkbox' ? checked : value
            }));
        }
    };

    const addArrayItem = (section, template) => {
        setFormData(prev => ({
            ...prev,
            [section]: [...prev[section], { ...template }]
        }));
    };

    const removeArrayItem = (section, index) => {
        if (formData[section].length > 1) {
            setFormData(prev => ({
                ...prev,
                [section]: prev[section].filter((_, i) => i !== index)
            }));
        }
    };

    const validateUKMobile = (phone) => {
        const regex = /^(?:(?:\+44)|(?:0))7\d{9}$/;
        return regex.test(phone.replace(/\s+/g, ''));
    };

    const validateInitialFields = () => {
        const requiredFields = {
            driverName: 'Driver Name',
            driverTelephone: 'Driver Phone',
            accidentDate: 'Accident Date',
            accidentTime: 'Accident Time',
            accidentLocation: 'Accident Location',
            vehicleRegistration: 'Vehicle Registration',
            damageDescription: 'Damage Description'
        };

        for (const [field, label] of Object.entries(requiredFields)) {
            if (!formData[field]) {
                setError(`${label} is required`);
                return false;
            }
        }

        if (!validateUKMobile(formData.driverTelephone)) {
            setError('Please enter a valid UK mobile number');
            return false;
        }

        if (new Date(formData.accidentDate) > new Date()) {
            setError('Accident date cannot be in the future');
            return false;
        }

        return true;
    };

    const handlePhotoUpload = async (e) => {
        if (!reportId) {
            setError('Please save the initial report before uploading photos');
            return;
        }

        const files = Array.from(e.target.files);
        setPhotoUploading(true);
        setError(null);

        try {
            for (const file of files) {
                const formData = new FormData();
                formData.append('files', file);
                formData.append('photoType', 'accident');
                formData.append('caption', 'Accident photo');

                const response = await fetch(`${ApiRepos}AccidentReport/${reportId}/upload-photos`, {
                    method: 'POST',
                    credentials: 'include',
                    body: formData
                });

                if (!response.ok) throw new Error('Failed to upload photo');

                const uploadedPhotos = await response.json();
                setPhotos(prev => [...prev, ...uploadedPhotos]);
            }
        } catch (err) {
            setError('Failed to upload photos: ' + err.message);
        } finally {
            setPhotoUploading(false);
        }
    };

    const handleDeletePhoto = async (photoId) => {
        try {
            const response = await fetch(`${ApiRepos}AccidentReport/${reportId}/photos/${photoId}`, {
                method: 'DELETE',
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Failed to delete photo');

            setPhotos(prev => prev.filter(photo => photo.id !== photoId));
        } catch (err) {
            setError('Failed to delete photo: ' + err.message);
        }
    };

    const handleSaveDraft = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccessMessage(null);

        if (!validateInitialFields()) {
            return;
        }

        setLoading(true);

        try {
            const method = reportId ? 'PUT' : 'POST';
            const url = reportId ?
                `${ApiRepos}AccidentReport/${reportId}` :
                `${ApiRepos}AccidentReport`;

            const submissionData = {
                ...formData,
                status: AccidentReportStatus.Draft,
                driverDateOfBirth: formData.driverDateOfBirth ? formData.driverDateOfBirth : null,
                accidentDate: new Date(formData.accidentDate).toISOString()
            };

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(submissionData)
            });

            if (!response.ok) {
                throw new Error('Failed to save accident report');
            }

            const data = await response.json();
            if (!reportId) {
                setReportId(data.id);
                setSuccessMessage('Initial report saved. Please complete additional details and upload photos.');
            } else {
                setSuccessMessage('Draft saved successfully');
            }
        } catch (err) {
            setError(err.message);
            console.error('Error saving accident report:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitFinal = async () => {
        if (!reportId) {
            setError('Please save the initial report before submitting');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`${ApiRepos}AccidentReport/${reportId}/submit`, {
                method: 'PUT',
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to submit report');
            }

            setSuccessMessage('Report submitted successfully');
            navigate('/dashboard/AccidentReports');
        } catch (err) {
            setError('Failed to submit report: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return (
        <div className="accident-loading">
            <div className="accident-loading-text">Loading...</div>
        </div>
    );
    return (
        <div className="accident-container">
            <div className="accident-form-wrapper">
                {/* Status banner for submitted reports */}
                {formData.status !== AccidentReportStatus.Draft && (
                    <div className="accident-status-banner">
                        This report has been submitted and cannot be edited.
                        Status: {formData.statusDisplayName}
                    </div>
                )}

                <div className="accident-warning-notes">
                    <p>In the event of an accident:</p>
                    <ul>
                        <li>Immediately call us on 02392 002 002</li>
                        <li>Take details of any third party(s)</li>
                        <li>Take photos where possible</li>
                        <li>Complete this form thoroughly</li>
                    </ul>
                </div>

                {error && (
                    <div className="accident-error">
                        {error}
                    </div>
                )}

                {successMessage && (
                    <div className="accident-success">
                        {successMessage}
                    </div>
                )}

                <form onSubmit={handleSaveDraft} className="accident-form">
                    {/* Required Initial Fields Section */}
                    <div className="accident-form-section">
                        <h3>Required Initial Information</h3>

                        <div className="accident-form-row">
                            <div className="accident-form-group">
                                <label className="accident-checkbox-label">
                                    <input
                                        type="checkbox"
                                        checked={formData.isDriver}
                                        onChange={handleDriverCheckboxChange}
                                        className="accident-checkbox-input"
                                        disabled={formData.status !== AccidentReportStatus.Draft}
                                    />
                                    <span>I was the driver</span>
                                </label>
                            </div>
                        </div>

                        <div className="accident-form-row">
                            <div className="accident-form-group">
                                <label>Driver Name *</label>
                                <input
                                    type="text"
                                    name="driverName"
                                    value={formData.driverName}
                                    onChange={handleChange}
                                    required
                                    disabled={formData.isDriver || formData.status !== AccidentReportStatus.Draft}
                                    className="accident-input"
                                />
                            </div>
                            <div className="accident-form-group">
                                <label>Driver Phone *</label>
                                <input
                                    type="tel"
                                    name="driverTelephone"
                                    value={formData.driverTelephone}
                                    onChange={handleChange}
                                    required
                                    disabled={formData.isDriver || formData.status !== AccidentReportStatus.Draft}
                                    placeholder="+44 7XXX XXX XXX"
                                    className="accident-input"
                                />
                            </div>
                        </div>

                        <div className="accident-form-row">
                            <div className="accident-form-group">
                                <label>Date of Accident *</label>
                                <input
                                    type="date"
                                    name="accidentDate"
                                    value={formData.accidentDate}
                                    onChange={handleChange}
                                    max={getTodayString()}
                                    required
                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                    className="accident-input"
                                />
                            </div>
                            <div className="accident-form-group">
                                <label>Time of Accident *</label>
                                <input
                                    type="time"
                                    name="accidentTime"
                                    value={formData.accidentTime}
                                    onChange={handleChange}
                                    required
                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                    className="accident-input"
                                />
                            </div>
                        </div>
                        <div className="accident-form-row">
                            <div className="accident-form-group">
                                <label>Location *</label>
                                <textarea
                                    name="accidentLocation"
                                    value={formData.accidentLocation}
                                    onChange={handleChange}
                                    required
                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                    rows={3}
                                    placeholder="Please provide detailed location information"
                                    className="accident-textarea"
                                />
                            </div>
                        </div>

                        <div className="accident-form-row">
                            <div className="accident-form-group">
                                <label>Vehicle Registration *</label>
                                <input
                                    type="text"
                                    name="vehicleRegistration"
                                    value={formData.vehicleRegistration}
                                    onChange={handleChange}
                                    required
                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                    className="accident-input"
                                />
                            </div>
                        </div>

                        <div className="accident-form-row">
                            <div className="accident-form-group">
                                <label>Damage Description *</label>
                                <textarea
                                    name="damageDescription"
                                    value={formData.damageDescription}
                                    onChange={handleChange}
                                    required
                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                    rows={4}
                                    placeholder="Please describe the damage to the vehicle"
                                    className="accident-textarea"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Photo Upload Section - Only shown after initial save */}
                    {reportId && (
                        <div className="accident-form-section">
                            <h3>Accident Photos</h3>
                            <div className="accident-photo-upload">
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={handlePhotoUpload}
                                    disabled={photoUploading || formData.status !== AccidentReportStatus.Draft}
                                    className="accident-file-input"
                                />
                                {photoUploading && (
                                    <div className="accident-upload-status">
                                        Uploading photos...
                                    </div>
                                )}
                            </div>
                            <div className="accident-photos-grid">
                                {photos.map((photo) => (
                                    <div key={photo.id} className="accident-photo-item">
                                        <img src={photo.photoUrl} alt={photo.caption} />
                                        {formData.status === AccidentReportStatus.Draft && (
                                            <button
                                                type="button"
                                                onClick={() => handleDeletePhoto(photo.id)}
                                                className="accident-photo-delete"
                                            >
                                                Delete
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {/* Optional Fields - Only shown after initial save */}
                    {reportId && (
                        <>
                            {/* Additional Driver Details */}
                            <div className="accident-form-section">
                                <h3>Additional Driver Details</h3>
                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label>Email Address</label>
                                        <input
                                            type="email"
                                            name="driverEmail"
                                            value={formData.driverEmail}
                                            onChange={handleChange}
                                            disabled={formData.isDriver || formData.status !== AccidentReportStatus.Draft}
                                            className="accident-input"
                                        />
                                    </div>
                                    <div className="accident-form-group">
                                        <label>Date of Birth</label>
                                        <input
                                            type="date"
                                            name="driverDateOfBirth"
                                            value={formData.driverDateOfBirth || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                handleChange({
                                                    target: {
                                                        name: 'driverDateOfBirth',
                                                        value: value || null
                                                    }
                                                });
                                            }}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            max={getTodayString()}
                                            className="accident-input"
                                        />
                                    </div>
                                </div>

                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label>Driver Address</label>
                                        <textarea
                                            name="driverAddress"
                                            value={formData.driverAddress}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            rows={3}
                                            className="accident-textarea"
                                        />
                                    </div>
                                </div>

                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label>Driving License Number</label>
                                        <input
                                            type="text"
                                            name="drivingLicenceNumber"
                                            value={formData.drivingLicenceNumber}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            className="accident-input"
                                        />
                                    </div>
                                    <div className="accident-form-group">
                                        <label>License Held Duration</label>
                                        <input
                                            type="text"
                                            name="licenceHeldDuration"
                                            value={formData.licenceHeldDuration}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            placeholder="e.g. 5 years"
                                            className="accident-input"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Vehicle Details */}
                            <div className="accident-form-section">
                                <h3>Vehicle Details</h3>
                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label>Vehicle Make</label>
                                        <input
                                            type="text"
                                            name="vehicleMake"
                                            value={formData.vehicleMake}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            className="accident-input"
                                        />
                                    </div>
                                    <div className="accident-form-group">
                                        <label>Vehicle Model</label>
                                        <input
                                            type="text"
                                            name="vehicleModel"
                                            value={formData.vehicleModel}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            className="accident-input"
                                        />
                                    </div>
                                </div>

                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label>Hire Vehicle Purpose</label>
                                        <textarea
                                            name="hireVehiclePurpose"
                                            value={formData.hireVehiclePurpose}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            rows={3}
                                            className="accident-textarea"
                                            placeholder="Please describe the purpose of hiring the vehicle"
                                        />
                                    </div>
                                </div>

                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label>Nature of Goods</label>
                                        <textarea
                                            name="natureOfGoods"
                                            value={formData.natureOfGoods}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            rows={3}
                                            className="accident-textarea"
                                            placeholder="If carrying goods, please describe their nature"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Accident Details */}
                            <div className="accident-form-section">
                                <h3>Accident Details</h3>
                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label>Road Conditions</label>
                                        <input
                                            type="text"
                                            name="roadConditions"
                                            value={formData.roadConditions}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            placeholder="e.g. Dry, Wet, Icy"
                                            className="accident-input"
                                        />
                                    </div>
                                    <div className="accident-form-group">
                                        <label>Weather Conditions</label>
                                        <input
                                            type="text"
                                            name="weatherConditions"
                                            value={formData.weatherConditions}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            placeholder="e.g. Clear, Rainy, Foggy"
                                            className="accident-input"
                                        />
                                    </div>
                                </div>

                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label>Estimated Speed</label>
                                        <input
                                            type="text"
                                            name="estimatedSpeed"
                                            value={formData.estimatedSpeed}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            placeholder="e.g. 30 mph"
                                            className="accident-input"
                                        />
                                    </div>
                                    <div className="accident-form-group">
                                        <label>Lights Used</label>
                                        <input
                                            type="text"
                                            name="lightsUsed"
                                            value={formData.lightsUsed}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            placeholder="e.g. Headlights, Fog lights"
                                            className="accident-input"
                                        />
                                    </div>
                                </div>

                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label>Road Position</label>
                                        <input
                                            type="text"
                                            name="roadPosition"
                                            value={formData.roadPosition}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            placeholder="e.g. Left lane, Right lane"
                                            className="accident-input"
                                        />
                                    </div>
                                </div>

                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label className="accident-checkbox-label">
                                            <input
                                                type="checkbox"
                                                name="wasHirerResponsible"
                                                checked={formData.wasHirerResponsible}
                                                onChange={handleChange}
                                                disabled={formData.status !== AccidentReportStatus.Draft}
                                                className="accident-checkbox-input"
                                            />
                                            <span>Were you responsible for the accident?</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label>Accident Description</label>
                                        <textarea
                                            name="accidentDescription"
                                            value={formData.accidentDescription}
                                            onChange={handleChange}
                                            disabled={formData.status !== AccidentReportStatus.Draft}
                                            rows={6}
                                            className="accident-textarea"
                                            placeholder="Please provide a detailed description of how the accident occurred"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Third Parties Section */}
                            <div className="accident-form-section">
                                <h3>Third Parties</h3>
                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label className="accident-checkbox-label">
                                            <input
                                                type="checkbox"
                                                name="hasThirdParties"
                                                checked={formData.hasThirdParties}
                                                onChange={handleChange}
                                                disabled={formData.status !== AccidentReportStatus.Draft}
                                                className="accident-checkbox-input"
                                            />
                                            <span>Were there any third parties involved?</span>
                                        </label>
                                    </div>
                                </div>

                                {formData.hasThirdParties && formData.thirdParties.map((party, index) => (
                                    <div key={index} className="accident-array-item">
                                        <div className="accident-array-item-header">
                                            <span className="accident-array-item-title">Third Party {index + 1}</span>
                                            {formData.thirdParties.length > 1 && formData.status === AccidentReportStatus.Draft && (
                                                <button
                                                    type="button"
                                                    onClick={() => removeArrayItem('thirdParties', index)}
                                                    className="accident-remove-button"
                                                >
                                                    Remove
                                                </button>
                                            )}
                                        </div>

                                        {/* Third party form fields */}
                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={party.name}
                                                    onChange={(e) => handleChange(e, 'thirdParties', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    className="accident-input"
                                                />
                                            </div>
                                            <div className="accident-form-group">
                                                <label>Phone Number</label>
                                                <input
                                                    type="tel"
                                                    name="telephoneNumber"
                                                    value={party.telephoneNumber}
                                                    onChange={(e) => handleChange(e, 'thirdParties', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    className="accident-input"
                                                />
                                            </div>
                                        </div>
                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Address</label>
                                                <textarea
                                                    name="address"
                                                    value={party.address}
                                                    onChange={(e) => handleChange(e, 'thirdParties', index)}
                                                    rows={3}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    className="accident-textarea"
                                                />
                                            </div>
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Vehicle Make & Model</label>
                                                <input
                                                    type="text"
                                                    name="vehicleMakeModel"
                                                    value={party.vehicleMakeModel}
                                                    onChange={(e) => handleChange(e, 'thirdParties', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    className="accident-input"
                                                />
                                            </div>
                                            <div className="accident-form-group">
                                                <label>Vehicle Registration</label>
                                                <input
                                                    type="text"
                                                    name="vehicleRegistration"
                                                    value={party.vehicleRegistration}
                                                    onChange={(e) => handleChange(e, 'thirdParties', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    className="accident-input"
                                                />
                                            </div>
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Insurance Details</label>
                                                <input
                                                    type="text"
                                                    name="insurersDetails"
                                                    value={party.insurersDetails}
                                                    onChange={(e) => handleChange(e, 'thirdParties', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    className="accident-input"
                                                />
                                            </div>
                                            <div className="accident-form-group">
                                                <label>Estimated Speed</label>
                                                <input
                                                    type="text"
                                                    name="speed"
                                                    value={party.speed}
                                                    onChange={(e) => handleChange(e, 'thirdParties', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    placeholder="e.g. 30 mph"
                                                    className="accident-input"
                                                />
                                            </div>
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Vehicle Damage</label>
                                                <textarea
                                                    name="vehicleDamage"
                                                    value={party.vehicleDamage}
                                                    onChange={(e) => handleChange(e, 'thirdParties', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    rows={3}
                                                    className="accident-textarea"
                                                />
                                            </div>
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Number of Occupants</label>
                                                <input
                                                    type="number"
                                                    name="numberOfOccupants"
                                                    value={party.numberOfOccupants || ''}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        handleChange({
                                                            target: {
                                                                name: 'numberOfOccupants',
                                                                value: value === '' ? null : parseInt(value, 10)
                                                            }
                                                        }, 'thirdParties', index)
                                                    }}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    min="1"
                                                    className="accident-input"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {formData.hasThirdParties && formData.status === AccidentReportStatus.Draft && (
                                    <button
                                        type="button"
                                        onClick={() => addArrayItem('thirdParties', {
                                            name: '',
                                            address: '',
                                            telephoneNumber: '',
                                            vehicleMakeModel: '',
                                            vehicleRegistration: '',
                                            insurersDetails: '',
                                            speed: '',
                                            vehicleDamage: '',
                                            numberOfOccupants: null
                                        })}
                                        className="accident-add-button"
                                    >
                                        Add Another Third Party
                                    </button>
                                )}
                            </div>

                            {/* Injured Persons Section */}
                            <div className="accident-form-section">
                                <h3>Injured Persons</h3>
                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label className="accident-checkbox-label">
                                            <input
                                                type="checkbox"
                                                name="hasInjuredParties"
                                                checked={formData.hasInjuredParties}
                                                onChange={handleChange}
                                                disabled={formData.status !== AccidentReportStatus.Draft}
                                                className="accident-checkbox-input"
                                            />
                                            <span>Were there any injuries?</span>
                                        </label>
                                    </div>
                                </div>
                                {formData.hasInjuredParties && formData.injuredPersons.map((person, index) => (
                                    <div key={index} className="accident-array-item">
                                        <div className="accident-array-item-header">
                                            <span className="accident-array-item-title">Injured Person {index + 1}</span>
                                            {formData.injuredPersons.length > 1 && formData.status === AccidentReportStatus.Draft && (
                                                <button
                                                    type="button"
                                                    onClick={() => removeArrayItem('injuredPersons', index)}
                                                    className="accident-remove-button"
                                                >
                                                    Remove
                                                </button>
                                            )}
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={person.name}
                                                    onChange={(e) => handleChange(e, 'injuredPersons', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    className="accident-input"
                                                />
                                            </div>
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Address</label>
                                                <textarea
                                                    name="address"
                                                    value={person.address}
                                                    onChange={(e) => handleChange(e, 'injuredPersons', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    rows={3}
                                                    className="accident-textarea"
                                                />
                                            </div>
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Injury Details</label>
                                                <textarea
                                                    name="injuryDetails"
                                                    value={person.injuryDetails}
                                                    onChange={(e) => handleChange(e, 'injuredPersons', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    rows={3}
                                                    className="accident-textarea"
                                                />
                                            </div>
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label className="accident-checkbox-label">
                                                    <input
                                                        type="checkbox"
                                                        name="ambulanceAttended"
                                                        checked={person.ambulanceAttended}
                                                        onChange={(e) => handleChange(e, 'injuredPersons', index)}
                                                        disabled={formData.status !== AccidentReportStatus.Draft}
                                                        className="accident-checkbox-input"
                                                    />
                                                    <span>Did an ambulance attend?</span>
                                                </label>
                                            </div>
                                        </div>

                                        {person.ambulanceAttended && (
                                            <div className="accident-form-row">
                                                <div className="accident-form-group">
                                                    <label>Ambulance Details</label>
                                                    <textarea
                                                        name="ambulanceDetails"
                                                        value={person.ambulanceDetails}
                                                        onChange={(e) => handleChange(e, 'injuredPersons', index)}
                                                        disabled={formData.status !== AccidentReportStatus.Draft}
                                                        rows={2}
                                                        className="accident-textarea"
                                                        placeholder="Please provide any relevant ambulance/paramedic details"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}

                                {formData.hasInjuredParties && formData.status === AccidentReportStatus.Draft && (
                                    <button
                                        type="button"
                                        onClick={() => addArrayItem('injuredPersons', {
                                            name: '',
                                            address: '',
                                            injuryDetails: '',
                                            ambulanceAttended: false,
                                            ambulanceDetails: ''
                                        })}
                                        className="accident-add-button"
                                    >
                                        Add Another Injured Person
                                    </button>
                                )}
                            </div>

                            {/* Passengers Section */}
                            <div className="accident-form-section">
                                <h3>Passengers</h3>
                                {formData.passengers.map((passenger, index) => (
                                    <div key={index} className="accident-array-item">
                                        <div className="accident-array-item-header">
                                            <span className="accident-array-item-title">Passenger {index + 1}</span>
                                            {formData.passengers.length > 1 && formData.status === AccidentReportStatus.Draft && (
                                                <button
                                                    type="button"
                                                    onClick={() => removeArrayItem('passengers', index)}
                                                    className="accident-remove-button"
                                                >
                                                    Remove
                                                </button>
                                            )}
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={passenger.name}
                                                    onChange={(e) => handleChange(e, 'passengers', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    className="accident-input"
                                                />
                                            </div>
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Address</label>
                                                <textarea
                                                    name="address"
                                                    value={passenger.address}
                                                    onChange={(e) => handleChange(e, 'passengers', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    rows={3}
                                                    className="accident-textarea"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {formData.status === AccidentReportStatus.Draft && (
                                    <button
                                        type="button"
                                        onClick={() => addArrayItem('passengers', {
                                            name: '',
                                            address: ''
                                        })}
                                        className="accident-add-button"
                                    >
                                        Add Passenger
                                    </button>
                                )}
                            </div>
                            {/* Witnesses Section */}
                            <div className="accident-form-section">
                                <h3>Witnesses</h3>
                                {formData.witnesses.map((witness, index) => (
                                    <div key={index} className="accident-array-item">
                                        <div className="accident-array-item-header">
                                            <span className="accident-array-item-title">Witness {index + 1}</span>
                                            {formData.witnesses.length > 1 && formData.status === AccidentReportStatus.Draft && (
                                                <button
                                                    type="button"
                                                    onClick={() => removeArrayItem('witnesses', index)}
                                                    className="accident-remove-button"
                                                >
                                                    Remove
                                                </button>
                                            )}
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={witness.name}
                                                    onChange={(e) => handleChange(e, 'witnesses', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    className="accident-input"
                                                />
                                            </div>
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Address</label>
                                                <textarea
                                                    name="address"
                                                    value={witness.address}
                                                    onChange={(e) => handleChange(e, 'witnesses', index)}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    rows={3}
                                                    className="accident-textarea"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {formData.status === AccidentReportStatus.Draft && (
                                    <button
                                        type="button"
                                        onClick={() => addArrayItem('witnesses', {
                                            name: '',
                                            address: ''
                                        })}
                                        className="accident-add-button"
                                    >
                                        Add Witness
                                    </button>
                                )}
                            </div>

                            {/* Police Details */}
                            <div className="accident-form-section">
                                <h3>Police Details</h3>
                                <div className="accident-form-row">
                                    <div className="accident-form-group">
                                        <label className="accident-checkbox-label">
                                            <input
                                                type="checkbox"
                                                name="hasPoliceInvolved"
                                                checked={formData.hasPoliceInvolved}
                                                onChange={handleChange}
                                                disabled={formData.status !== AccidentReportStatus.Draft}
                                                className="accident-checkbox-input"
                                            />
                                            <span>Were the police involved?</span>
                                        </label>
                                    </div>
                                </div>

                                {formData.hasPoliceInvolved && (
                                    <>
                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Officer Name</label>
                                                <input
                                                    type="text"
                                                    name="officerName"
                                                    value={formData.policeDetails.officerName}
                                                    onChange={(e) => handleChange(e, 'policeDetails')}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    className="accident-input"
                                                />
                                            </div>
                                            <div className="accident-form-group">
                                                <label>Officer Number</label>
                                                <input
                                                    type="text"
                                                    name="officerNumber"
                                                    value={formData.policeDetails.officerNumber}
                                                    onChange={(e) => handleChange(e, 'policeDetails')}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    className="accident-input"
                                                />
                                            </div>
                                        </div>

                                        <div className="accident-form-row">
                                            <div className="accident-form-group">
                                                <label>Police Station Address</label>
                                                <textarea
                                                    name="stationAddress"
                                                    value={formData.policeDetails.stationAddress}
                                                    onChange={(e) => handleChange(e, 'policeDetails')}
                                                    disabled={formData.status !== AccidentReportStatus.Draft}
                                                    rows={3}
                                                    className="accident-textarea"
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}

                    {/* Form Action Buttons */}
                    <div className="accident-form-actions">
                        <button
                            type="button"
                            onClick={() => navigate(-1)}
                            className="accident-cancel-button"
                        >
                            Cancel
                        </button>

                        {formData.status === AccidentReportStatus.Draft && (
                            <>
                                <button
                                    type="submit"
                                    className="accident-submit-button"
                                    disabled={loading}
                                >
                                    Save Draft
                                </button>

                                {canBeSubmitted && (
                                    <button
                                        type="button"
                                        onClick={handleSubmitFinal}
                                        className="accident-submit-button"
                                        style={{
                                            marginLeft: '10px',
                                            backgroundColor: '#48bb78'
                                        }}
                                        disabled={loading}
                                    >
                                        Submit Report
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AccidentSupport;