import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useAuth } from '../../../auth/AuthProvider';
import './Orders.css';

const ViewOrders = () => {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterBy, setFilterBy] = useState('received');
    const [sortField, setSortField] = useState('createdDate');
    const [sortDirection, setSortDirection] = useState('desc');
    const navigate = useNavigate();

    const { isAdmin, isManager } = useAuth();
    const isManagerOrAdmin = isAdmin || isManager;

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const url = `${ApiRepos}order`;

                const response = await fetch(url, {
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch orders');
                }

                const data = await response.json();
                setOrders(data);
                setFilteredOrders(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    useEffect(() => {
        let filtered = [...orders];

        // Apply search filter
        if (searchTerm) {
            const searchLower = searchTerm.toLowerCase();
            filtered = filtered.filter(order =>
                order.driverName?.toLowerCase().includes(searchLower) ||
                order.driverEmail?.toLowerCase().includes(searchLower) ||
                order.id?.toString().includes(searchLower) ||
                order.businessName?.toLowerCase().includes(searchLower) ||
                order.poNumber?.toLowerCase().includes(searchLower)
            );
        }

        // Apply date/logistics filter
        switch (filterBy) {
            case 'received':
                filtered = filtered.filter(order => order.status === 1);
                break;
            case 'processed':
                filtered = filtered.filter(order => order.status === 5);
                break;
            case 'cancelled':
                filtered = filtered.filter(order => order.status === 9);
                break;
            case 'today':
                const today = new Date().toDateString();
                filtered = filtered.filter(order =>
                    new Date(order.createdDate).toDateString() === today
                );
                break;
            case 'thisWeek':
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
                filtered = filtered.filter(order =>
                    new Date(order.createdDate) > oneWeekAgo
                );
                break;
            case 'collection':
                filtered = filtered.filter(order => order.logistics === 'Collect');
                break;
            case 'delivery':
                filtered = filtered.filter(order => order.logistics === 'Delivery');
                break;
            default:
                break;
        }

        // Apply sorting
        filtered.sort((a, b) => {
            let comparison = 0;
            switch (sortField) {
                case 'id':
                    comparison = a.id - b.id;
                    break;
                case 'driverName':
                    comparison = a.driverName.localeCompare(b.driverName);
                    break;
                case 'createdDate':
                    comparison = new Date(a.createdDate) - new Date(b.createdDate);
                    break;
                default:
                    comparison = 0;
            }
            return sortDirection === 'desc' ? -comparison : comparison;
        });

        setFilteredOrders(filtered);
    }, [orders, searchTerm, filterBy, sortField, sortDirection]);

    const handleSort = (field) => {
        if (field === sortField) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const formatDateTime = (date, hour, minute) => {
        const formattedDate = new Date(date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
        return `${formattedDate} ${hour}:${minute}`;
    };

    const getSortIcon = (field) => {
        if (sortField !== field) return '';
        return sortDirection === 'asc' ? '?' : '?';
    };

    if (loading) return (
        <div className="flex justify-center items-center h-screen">
            <div className="text-xl">Loading...</div>
        </div>
    );

    if (error) return (
        <div className="p-6">
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                Error: {error}
            </div>
        </div>
    );

    const SearchAndFilterControls = ({ isAdmin, isManager, searchTerm, setSearchTerm, filterBy, setFilterBy, totalCount, filteredCount, itemType }) => {
        return (
            <>
                <div className="mb-6 flex flex-wrap gap-4">
                    <div className="flex-1 min-w-[200px]">
                        <input
                            type="text"
                            placeholder={isAdmin
                                ? "Search by ID, driver name, email, business or PO number..."
                                : "Search by ID, driver name, email or PO number..."}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                        />
                    </div>
                    {(isAdmin || isManager) && (
                        <div>
                            <select
                                value={filterBy}
                                onChange={(e) => setFilterBy(e.target.value)}
                                className="filter-select"
                            >
                                <option value="all">All Orders</option>
                                <option value="received">Received</option>
                                <option value="processed">Processed</option>
                                <option value="cancelled">Cancelled</option>
                                <option value="today">Created Today</option>
                                <option value="thisWeek">Created This Week</option>
                                <option value="collection">Collection Only</option>
                                <option value="delivery">Delivery Only</option>
                            </select>
                        </div>
                    )}
                </div>

                {/* Results Count - Only show for managers and admins */}
                {(isAdmin || isManager) && (
                    <div className="mb-4 text-sm text-gray-600">
                        Showing {filteredCount} of {totalCount} {itemType}
                    </div>
                )}
            </>
        );
    };

    return (
        <div className="orders-container">
            <div className="orders-header">
                <h1>Orders</h1>
                {!isAdmin && (
                    <button
                        onClick={() => navigate('/dashboard/order')}
                        className="dashboard-button"
                    >
                        New Order
                    </button>
                )}
            </div>

            {/* Only show search input for regular users, full controls for managers/admins */}
            {!isAdmin && !isManager ? (
                <div className="mb-6">
                    <input
                        type="text"
                        placeholder="Search by ID, driver name, email or PO number..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                </div>
            ) : (
                <SearchAndFilterControls
                    isAdmin={isAdmin}
                    isManager={isManager}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    totalCount={orders.length}
                    filteredCount={filteredOrders.length}
                    itemType="orders"
                />
            )}

            {/* Orders Table */}
            <div className="orders-table-container">
                <table className="orders-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('id')}>
                                Ref {getSortIcon('id')}
                            </th>
                            <th>Status</th>
                            <th onClick={() => handleSort('driverName')}>
                                Driver {getSortIcon('driverName')}
                            </th>
                            <th>
                                Vehicle Type
                            </th>
                            <th>
                                Hire From
                            </th>
                            <th>
                                Hire To
                            </th>
                            <th>
                                Logistics
                            </th>
                            {isManagerOrAdmin && (
                                <th>
                                    Ordered By
                                </th>
                            )}
                            {isAdmin && (
                                <th>
                                    Business
                                </th>
                            )}
                            <th onClick={() => handleSort('createdDate')}>
                                Order Date {getSortIcon('createdDate')}
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrders.map(order => (
                            <tr key={order.id}>
                                <td>{order.id}</td>
                                <td>
                                    {order.status === 0 ? 'Quote' :
                                        order.status === 1 ? 'Received' :
                                            order.status === 5 ? 'Processed' :
                                                order.status === 9 ? 'Cancelled' :
                                                    'Unknown'}
                                </td>
                                <td>{order.driverName}</td>
                                <td>{order.vehicleTypeName}</td>
                                <td>
                                    {formatDateTime(order.hireFromDate, order.hireFromHour, order.hireFromMinute)}
                                </td>
                                <td>
                                    {formatDateTime(order.hireToDate, order.hireToHour, order.hireToMinute)}
                                </td>
                                <td>{order.logistics}</td>
                                {isManagerOrAdmin && (
                                    <td>
                                        {order.requestedByUser}
                                    </td>
                                )}
                                {isAdmin && (
                                    <td>
                                        {order.businessName}
                                    </td>
                                )}
                                <td>
                                    {new Date(order.createdDate).toLocaleString('en-GB')}
                                </td>
                                <td>
                                    <button
                                        onClick={() => navigate(`/dashboard/orders/${order.id}`)}
                                        className="action-button edit-button"
                                    >
                                        View Details
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* No Results Message */}
            {filteredOrders.length === 0 && (
                <div className="text-center py-4 text-gray-500">
                    No orders found matching your criteria
                </div>
            )}
        </div>
    );
};

export default ViewOrders;