import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import UserCard from './UserCard';
import './ManageUsers.css';

const ManageUsers = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [showAddUserForm, setShowAddUserForm] = useState(false);
    const [businesses, setBusinesses] = useState([]);

    // Filter states
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [filterActive, setFilterActive] = useState('all');
    const [selectedNewUserBusiness, setSelectedNewUserBusiness] = useState('');

    useEffect(() => {
        fetchCurrentUserAndUsers();
        if (currentUser?.userLevel === 9) {
            fetchBusinesses();
        }
    }, [currentUser?.userLevel]);

    const fetchBusinesses = async () => {
        try {
            const response = await fetch(`${ApiRepos}business/list`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch businesses');
            const data = await response.json();
            setBusinesses(data);
        } catch (err) {
            console.error('Error fetching businesses:', err);
        }
    };

    const fetchCurrentUserAndUsers = async () => {
        try {
            const currentUserResponse = await fetch(`${ApiRepos}account/current-user`, {
                credentials: 'include'
            });

            if (!currentUserResponse.ok) throw new Error('Failed to fetch current user');
            const currentUserData = await currentUserResponse.json();
            setCurrentUser(currentUserData);

            if (currentUserData.userLevel < 1) {
                setError('Unauthorized access');
                return;
            }

            const usersResponse = await fetch(`${ApiRepos}users`, {
                credentials: 'include'
            });

            if (!usersResponse.ok) throw new Error('Failed to fetch users');
            const usersData = await usersResponse.json();
            setUsers(usersData);
            setFilteredUsers(usersData);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let filtered = [...users];

        // Apply search filter
        if (searchTerm) {
            const term = searchTerm.toLowerCase();
            filtered = filtered.filter(user =>
                user.firstName?.toLowerCase().includes(term) ||
                user.lastName?.toLowerCase().includes(term) ||
                user.emailAddress?.toLowerCase().includes(term)
            );
        }

        // Apply business filter (admin only)
        if (selectedBusiness && currentUser?.userLevel === 9) {
            filtered = filtered.filter(user => user.businessId === parseInt(selectedBusiness));
        }

        // Apply active/inactive filter
        if (filterActive !== 'all') {
            filtered = filtered.filter(user =>
                user.isActive === (filterActive === 'active')
            );
        }

        setFilteredUsers(filtered);
    }, [users, searchTerm, selectedBusiness, filterActive, currentUser?.userLevel]);

    const handleUserUpdated = (updatedUser) => {
        setUsers(prevUsers =>
            prevUsers.map(user =>
                user.id === updatedUser.id ? updatedUser : user
            )
        );
        setSuccessMessage('User updated successfully');
    };

    const handleStatusToggled = (userId, newStatus) => {
        setUsers(prevUsers =>
            prevUsers.map(user =>
                user.id === userId ? { ...user, isActive: newStatus } : user
            )
        );
        setSuccessMessage(`User ${newStatus ? 'activated' : 'deactivated'} successfully`);
    };

    const handleUserAdded = (newUser) => {
        setUsers(prevUsers => [...prevUsers, newUser]);
        setSuccessMessage('User added successfully');
        setShowAddUserForm(false);
        setSelectedNewUserBusiness('');
    };

    // Redirect if user is not authorized
    if (!loading && (!currentUser || currentUser.userLevel < 1)) {
        return <Navigate to="/dashboard" replace />;
    }

    if (loading) return <div className="profile-loading">Loading...</div>;

    return (
        <div className="manageusers-container">
            <div className="manageusers-header">
                <h1>MANAGE USERS</h1>
                {!showAddUserForm && (
                    <button
                        className="action-button add-new"
                        onClick={() => setShowAddUserForm(true)}
                    >
                        ADD NEW USER
                    </button>
                )}
            </div>
            

            {error && (
                <div className="error-message">{error}</div>
            )}
            {successMessage && (
                <div className="success-message">{successMessage}</div>
            )}
            
            {/* Filters Section - Only show when not adding a user */}
            {!showAddUserForm && (
                <div className="filters-section flex gap-4 mb-6">
                    <input
                        type="text"
                        placeholder="Search by name or email..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input flex-1"
                    />

                    {currentUser?.userLevel === 9 && (
                        <select
                            value={selectedBusiness}
                            onChange={(e) => setSelectedBusiness(e.target.value)}
                            className="manageusers-filter-select"
                        >
                            <option value="">All Businesses</option>
                            {businesses.map(business => (
                                <option key={business.id} value={business.id}>
                                    {business.businessName}
                                </option>
                            ))}
                        </select>
                    )}

                    <select
                        value={filterActive}
                        onChange={(e) => setFilterActive(e.target.value)}
                        className="manageusers-filter-select"
                    >
                        <option value="all">All Users</option>
                        <option value="active">Active Users</option>
                        <option value="inactive">Inactive Users</option>
                    </select>
                </div>
            )}

            <div className="white-container">
                {showAddUserForm && (
                    <div className="new-user-section">
                        <h3 className="text-lg font-medium mb-4">Add New User</h3>
                        <UserCard
                            user={{
                                firstName: '',
                                lastName: '',
                                emailAddress: '',
                                mobileNumber: '',
                                userLevel: 1,
                                isActive: true,
                                profilePictureUrl: '',
                                businessId: currentUser?.userLevel === 9 ? selectedNewUserBusiness : currentUser?.businessId
                            }}
                            isNew={true}
                            onUserUpdated={handleUserAdded}
                            onCancel={() => {
                                setShowAddUserForm(false);
                                setSelectedNewUserBusiness('');
                            }}
                            currentUserLevel={currentUser.userLevel}
                            selectedNewUserBusiness={selectedNewUserBusiness}
                            setSelectedNewUserBusiness={setSelectedNewUserBusiness}
                            businesses={businesses}
                        />
                    </div>
                )}

                {!showAddUserForm && (
                    <div className="users-grid">
                        {filteredUsers.map(user => (
                            <UserCard
                                key={user.id}
                                user={user}
                                onUserUpdated={handleUserUpdated}
                                onStatusToggled={handleStatusToggled}
                                currentUserLevel={currentUser.userLevel}
                            />
                        ))}
                    </div>
                )}

                {!showAddUserForm && filteredUsers.length === 0 && (
                    <div className="text-center py-4 text-gray-500">
                        No users found matching your criteria
                    </div>
                )}
            </div>
        </div>
    );
};

export default ManageUsers;