import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useAuth } from '../../../auth/AuthProvider';
import './Quotes.css';

const ViewQuotes = () => {
    const [quotes, setQuotes] = useState([]);
    const [filteredQuotes, setFilteredQuotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterBy, setFilterBy] = useState('all');
    const [sortField, setSortField] = useState('createdDate');
    const [sortDirection, setSortDirection] = useState('desc');
    const navigate = useNavigate();

    const { isAdmin, isManager, user } = useAuth();
    const isManagerOrAdmin = isAdmin || isManager;

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                const url = `${ApiRepos}order/quotes`; // Always fetch quotes only

                const response = await fetch(url, {
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch quotes');
                }

                const data = await response.json();
                setQuotes(data);
                setFilteredQuotes(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchQuotes();
    }, []);

    useEffect(() => {
        let filtered = [...quotes];

        // First filter by user ID for regular users
        if (!isManagerOrAdmin) {
            filtered = filtered.filter(quote => quote.userId === user.id);
        }

        // Apply search filter
        if (searchTerm) {
            const searchLower = searchTerm.toLowerCase();
            filtered = filtered.filter(quote =>
                quote.driverName?.toLowerCase().includes(searchLower) ||
                quote.driverEmail?.toLowerCase().includes(searchLower) ||
                quote.id?.toString().includes(searchLower) ||
                quote.businessName?.toLowerCase().includes(searchLower) ||
                quote.poNumber?.toLowerCase().includes(searchLower)
            );
        }

        // Apply status/date filter
        switch (filterBy) {
            case 'pending':
                filtered = filtered.filter(quote => quote.quoteAmount === null && quote.status !== 10);
                break;
            case 'quoted':
                filtered = filtered.filter(quote => quote.quoteAmount !== null && quote.status !== 10);
                break;
            case 'cancelled':
                filtered = filtered.filter(quote => quote.status === 10);
                break;
            case 'today':
                const today = new Date().toDateString();
                filtered = filtered.filter(quote =>
                    new Date(quote.createdDate).toDateString() === today
                );
                break;
            case 'thisWeek':
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
                filtered = filtered.filter(quote =>
                    new Date(quote.createdDate) > oneWeekAgo
                );
                break;
            case 'collection':
                filtered = filtered.filter(quote => quote.logistics === 'Collect');
                break;
            case 'delivery':
                filtered = filtered.filter(quote => quote.logistics === 'Delivery');
                break;
            default:
                break;
        }

        // Apply sorting
        filtered.sort((a, b) => {
            let comparison = 0;
            switch (sortField) {
                case 'id':
                    comparison = a.id - b.id;
                    break;
                case 'driverName':
                    comparison = (a.driverName || '').localeCompare(b.driverName || '');
                    break;
                case 'createdDate':
                    comparison = new Date(a.createdDate) - new Date(b.createdDate);
                    break;
                case 'quoteAmount':
                    comparison = (a.quoteAmount || 0) - (b.quoteAmount || 0);
                    break;
                default:
                    comparison = 0;
            }
            return sortDirection === 'desc' ? -comparison : comparison;
        });

        setFilteredQuotes(filtered);
    }, [quotes, searchTerm, filterBy, sortField, sortDirection, isManagerOrAdmin, user]);

    const handleSort = (field) => {
        if (field === sortField) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const formatDateTime = (date, hour, minute) => {
        const formattedDate = new Date(date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
        return `${formattedDate} ${hour}:${minute}`;
    };

    const getSortIcon = (field) => {
        if (sortField !== field) return '';
        return sortDirection === 'asc' ? '?' : '?';
    };

    if (loading) return (
        <div className="flex justify-center items-center h-screen">
            <div className="text-xl">Loading...</div>
        </div>
    );

    if (error) return (
        <div className="p-6">
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                Error: {error}
            </div>
        </div>
    );


    const SearchAndFilterControls = ({ searchTerm, setSearchTerm, filterBy, setFilterBy, totalCount, filteredCount }) => {
        return (
            <>
                <div className="mb-6 flex flex-wrap gap-4">
                    <div className="flex-1 min-w-[200px]">
                        <input
                            type="text"
                            placeholder={isAdmin
                                ? "Search by ID, driver name, email, business or PO number..."
                                : "Search by ID, driver name, email or PO number..."}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                        />
                    </div>
                    <div>
                        <select
                            value={filterBy}
                            onChange={(e) => setFilterBy(e.target.value)}
                            className="filter-select"
                        >
                            <option value="all">All Quotes</option>
                            <option value="pending">Pending Quotes</option>
                            <option value="quoted">Quoted</option>
                            <option value="cancelled">Cancelled</option>
                            <option value="today">Created Today</option>
                            <option value="thisWeek">Created This Week</option>
                            <option value="collection">Collection Only</option>
                            <option value="delivery">Delivery Only</option>
                        </select>
                    </div>
                </div>

                {/* Results Count - Only show for managers and admins */}
                {(isAdmin || isManager) && (
                    <div className="mb-4 text-sm text-gray-600">
                        Showing {filteredCount} of {totalCount} quotes
                    </div>
                )}
            </>
        );
    };

    return (
        <div className="quotes-container">
            <div className="quotes-header">
                <h1>Quote Requests</h1>
                {!isAdmin && (
                    <button
                        onClick={() => navigate('/dashboard/quote')}
                        className="dashboard-button"
                    >
                        New Quote Request
                    </button>
                )}
            </div>

            {/* Show filter controls for all users */}
            <SearchAndFilterControls
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                totalCount={quotes.length}
                filteredCount={filteredQuotes.length}
            />

            {/* Quotes Table */}
            <div className="quotes-table-container">
                <table className="quotes-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('id')}>
                                Ref {getSortIcon('id')}
                            </th>
                            <th onClick={() => handleSort('driverName')}>
                                Driver {getSortIcon('driverName')}
                            </th>
                            <th>Email</th>
                            <th>Hire From</th>
                            <th>Hire To</th>
                            <th>Logistics</th>
                            <th onClick={() => handleSort('quoteAmount')}>
                                Quote Amount {getSortIcon('quoteAmount')}
                            </th>
                            {isManagerOrAdmin && (
                                <th>Requested By</th>
                            )}
                            {isAdmin && (
                                <th>Business</th>
                            )}
                            <th onClick={() => handleSort('createdDate')}>
                                Request Date {getSortIcon('createdDate')}
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredQuotes.map(quote => (
                            <tr key={quote.id} className={quote.status === 10 ? 'bg-red-50' : ''}>
                                <td>{quote.id}</td>
                                <td>{quote.driverName}</td>
                                <td>{quote.driverEmail}</td>
                                <td>
                                    {formatDateTime(quote.hireFromDate, quote.hireFromHour, quote.hireFromMinute)}
                                </td>
                                <td>
                                    {formatDateTime(quote.hireToDate, quote.hireToHour, quote.hireToMinute)}
                                </td>
                                <td>{quote.logistics}</td>
                                <td>
                                    {quote.status === 10 ? (
                                        <span className="text-red-600">Cancelled</span>
                                    ) : quote.quoteAmount ? (
                                            new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(quote.quoteAmount)
                                    ) : (
                                        <span className="text-amber-600">Pending</span>
                                    )}
                                </td>
                                {isManagerOrAdmin && (
                                    <td>{quote.requestedByUser}</td>
                                )}
                                {isAdmin && (
                                    <td>{quote.businessName}</td>
                                )}
                                <td>
                                    {new Date(quote.createdDate).toLocaleString('en-GB')}
                                </td>
                                <td>
                                    <button
                                        onClick={() => navigate(`/dashboard/quotes/${quote.id}`)}
                                        className="action-button edit-button"
                                    >
                                        View Details
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {filteredQuotes.length === 0 && (
                <div className="text-center py-4 text-gray-500">
                    No quotes found matching your criteria
                </div>
            )}
        </div>
    );
};

export default ViewQuotes;